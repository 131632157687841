import React, { useState, useEffect } from "react";
import axios from "axios";
import ClientListNew from "./ClientListNew";
import AdminDashboardLayout from "../AdminDashboardLayout";
import server from "../../utils/serverConfig";

const ClientListNewPage = () => {
  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString
    ? JSON.parse(adminDetailsString)
    : null;
  const adminEmail = adminDetails ? adminDetails.email : null;

  const [data, setData] = useState();
  const [userId, setUserId] = useState();
  const [allGroupsData, setAllGroupsData] = useState([]);
  const [allClientsData, setAllClientsData] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [isClientDataLoading, setIsClientDataLoading] = useState(true);
  const [isGroupDataLoading, setIsGroupDataLoading] = useState(true);

  const getAllGroupsData = async (userId) => {
    try {
      setIsGroupDataLoading(true);
      const response = await fetch(
        `${server.server.baseUrl}api/all-groups/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch groups");
      }

      const data = await response.json();

      // // Assuming the group names are in the `data` array
      const groupNames = data.data;

      // console.log("Group data:", groupNames);
      setAllGroupsData(data.data);
      // const allClientsDetails = groupNames.flatMap((group) => group.data);
      // console.log("Cleints Details data:", allClientsDetails);

      // setAllClientsData(allClientsDetails);
      setIsGroupDataLoading(false);
    } catch (error) {
      console.error("Error fetching group names:", error);
      setIsGroupDataLoading(false);
      return [];
    }
  };

  const getAllClientsData = async (userId) => {
    try {
      setIsClientDataLoading(true);
      const response = await fetch(
        `${server.server.baseUrl}api/all-clients/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch cleint data");
      }

      const data = await response.json();

      // // Assuming the group names are in the `data` array
      const clientsData = data.data;

      setAllClientsData(clientsData);
      setIsClientDataLoading(false);
    } catch (error) {
      console.error("Error fetching Client data:", error);
      setIsClientDataLoading(false);
      return [];
    }
  };

  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/admin/${adminEmail}`)
      .then((res) => {
        setData(res.data.data);
        // console.log("vsvvsvvsvsvsvs-", res.data.data?._id);
        setUserId(res.data.data?._id);
        getAllClientsData(res.data.data?._id);
        getAllGroupsData(res.data.data?._id);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserDeatils();
  }, [adminEmail, isDataFetching]);

  return (
    <AdminDashboardLayout>
      <ClientListNew
        userId={userId && userId}
        allClientsData={allClientsData && allClientsData}
        allGroupsData={allGroupsData && allGroupsData}
        setIsDataFetching={setIsDataFetching}
        getAllClientsData={getAllClientsData}
        getAllGroupsData={getAllGroupsData}
        isClientDataLoading={isClientDataLoading}
        isGroupDataLoading={isGroupDataLoading}
      />
    </AdminDashboardLayout>
  );
};

export default ClientListNewPage;
