import React, { useCallback, useEffect, useRef, useState } from "react";
import StockRebalanceCard from "./StockRebalanceCard";
import axios from "axios";
import server from "../../utils/serverConfig";
import { CirclePlusIcon } from "lucide-react";
import { debounce } from "lodash";
import StockDistributionTable from "./StockDistributionTable";

const StockDistribution = ({
  setAdviceEntries,
  adviceEntries,
  minimumPortfolioAmount,
  setSaveButton,
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddAdviceEntry = () => {
    const newEntry = {
      symbol: "",
      exchange: "",
      segment: "EQUITY",
      inputValue: "",
      symbols: [],
      value: 0,
    };
    setAdviceEntries([newEntry, ...adviceEntries]);
    setSaveButton(false);
  };

  const handleRemoveAdviceEntry = (index) => {
    const updatedEntries = adviceEntries.filter((_, i) => i !== index);
    setAdviceEntries(updatedEntries);
    setSaveButton(false);
  };

  const handleAdviceChange = (index, field, value) => {
    const updatedEntries = [...adviceEntries];
    updatedEntries[index][field] = Number(value); // Ensure value is a number
    setAdviceEntries(updatedEntries);
  };

  const handleIncrement = (index) => {
    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index
        ? { ...entry, value: (parseFloat(entry.value) || 0) + 1 }
        : entry
    );
    // Move the incremented entry to the first position
    const incrementedEntry = updatedEntries.splice(index, 1)[0];
    setAdviceEntries([incrementedEntry, ...updatedEntries]);
  };

  const handleDecrement = (index) => {
    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index
        ? { ...entry, value: Math.max((parseFloat(entry.value) || 0) - 1, 0) }
        : entry
    );
    setAdviceEntries(updatedEntries);
  };

  const fetchSymbols = async (index, inputValue) => {
    const data = JSON.stringify({
      symbol: inputValue,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${server.ccxtServer.baseUrl}get-symbol-name-exchange`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, symbols: response.data.match } : entry
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetchSymbols = useCallback(
    debounce((index, value) => {
      fetchSymbols(index, value);
    }, 300),
    []
  );

  const handleInputChange = (index, value) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, inputValue: value, symbol: value } : entry
      )
    );

    if (value.length >= 3) {
      debouncedFetchSymbols(index, value);
      setIsOpen(true);
    }
  };

  const handleSymbolSelect = (index, symbol, exchange) => {

    console.log("New Symbol",symbol)
    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index
        ? {
            ...entry,
            symbol: symbol,
            inputValue: '',
            exchange: exchange,
          }
        : entry
    );
    setAdviceEntries(updatedEntries);
    
  };

  return (
    <div>
      <div className="flex flex-col space-y-3 h-[400px]  py-4 ">
        {/* <div className="w-full grid grid-cols-8 lg:grid-cols-10 h-[50px] items-center lg:px-2 py-2">
          <div className="col-span-1 flex flex-row justify-center">
            <CirclePlusIcon
              className="w-4 h-4 md:w-6 md:h-6    text-green-600 cursor-pointer"
              onClick={handleAddAdviceEntry}
            />
          </div>
          <div className="flex items-center text-center text-[11px] md:text-[14px] text-[#000000]/60 font-poppins font-medium col-span-3 lg:col-span-5">
            Stock Name
          </div>
          <div className="flex items-center justify-center text-center text-[11px] md:text-[14px] text-[#000000]/60 font-poppins font-medium col-span-2 lg:col-span-2">
            Allocation (in%)
          </div>
          <div className="flex items-center justify-center text-center text-[11px] md:text-[14px] text-[#000000]/60 font-poppins font-medium col-span-2 lg:col-span-2">
            Min. Investment Value
          </div>
        </div> */}

        <StockDistributionTable
          handleRemoveAdviceEntry={handleRemoveAdviceEntry}
          handleAddAdviceEntry={handleAddAdviceEntry}
          adviceEntries={adviceEntries}
       
          handleInputChange={handleInputChange}
          handleSymbolSelect={handleSymbolSelect}
          
          handleAdviceChange={handleAdviceChange}
          handleIncrement={handleIncrement}
          handleDecrement={handleDecrement}
          minimumPortfolioAmount={minimumPortfolioAmount}
          isOpen={isOpen}
          dropdownRef={dropdownRef}
          
        />
      </div>
    </div>
  );
};

export default StockDistribution;
