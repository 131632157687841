import React from "react";
import Alpha100 from "../../assests/alpha-100.png";
import VolatilityIcon from "../../assests/volatility.svg";
import { useNavigate } from "react-router-dom";
import server from "../../utils/serverConfig";
const StrategyCard = ({ fileName, overView, ele }) => {
  const navigate = useNavigate();

  const handleViewStrategy = () => {
    navigate(`/admin/admin-strategy/${fileName.toLowerCase().replace(/ /g, "_")}`);
    // navigate(`/clients-list/${fileName.toLowerCase()}`);
  };

  return (
    <div
      className="w-full  flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between border-b-[1px] lg:h-[100px] border-[#000000]/10 px-4 xxl:px-16  mt-4 py-2 cursor-pointer"
      onClick={handleViewStrategy}
    >
      <div className="flex items-start space-x-4 px-3 lg:px-0 lg:flex-row lg:space-x-8">
        {ele?.image ? (
          <img
            src={`${server.server.baseUrl}${ele.image}`}
            alt={ele.model_name}
            className="mt-1 lg:mt-0 h-[50px] w-[50px] lg:h-[70px] lg:w-[70px] rounded-md"
          />
        ) : (
          <img
            src={Alpha100}
            className="mt-1 lg:mt-0 h-[50px] w-[50px] lg:h-[70px] lg:w-[70px] rounded-md"
          />
        )}

        <div className="flex justify-center flex-col">
          <div className="flex items-center space-x-4">
            <h3
              onClick={handleViewStrategy}
              className="text-[18px] font-sans font-bold text-[#000000] cursor-pointer"
            >
              {fileName}
            </h3>
            <div className="text-xs font-poppins font-medium text-[#0E846D] bg-[#D5FFE7] rounded-[3px] px-2 py-1">
              New
            </div>
          </div>

          <p className="w-full lg:w-[300px] text-[12px] lg:text-[14px] font-poppins font-medium text-[#000000]/50">
            {overView.split(" ").slice(0, 10).join(" ")}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-x-0 lg:gap-x-12 ">
        <div className="flex flex-col space-y-2 items-center justify-center h-[80px] lg:px-4">
          <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
            CAGR
          </div>
          <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold ">
            +75.2%
          </p>
        </div>

        <div className="flex flex-col space-y-2 items-center justify-center h-[80px] lg:px-4">
          <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
            2Y Returns
          </div>
          <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold ">
            +775.2%
          </p>
        </div>

        <div className="flex flex-col space-y-2 items-center justify-center h-[80px] lg:px-4">
          <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
            Volatility
          </div>

          <div className="flex space-x-2">
            <div className="flex items-center justify-center">
              <img src={VolatilityIcon} className="h-[20px] w-[20px]" />
            </div>
            <p className="text-[14px] lg:text-[16px] text-[#000000] font-poppins font-semibold ">
              Low
            </p>
          </div>
        </div>

        <div className="flex flex-col space-y-2 items-center justify-center h-[80px] lg:px-4">
          <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
            Score
          </div>
          <p className="flex items-center justify-center text-[10px] lg:text-[12px] rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[20px] w-[20px] lg:h-[24px] lg:w-[24px] ">
            10
          </p>
        </div>
      </div>
    </div>
  );
};

export default StrategyCard;
