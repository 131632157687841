// test environment server

// const server = {
//   ccxtServer: {
//     baseUrl: `https://ccxt.alphaquark-case.com/`,
//   },
//   server: {
//     baseUrl: `https://alphaquarktest.alphaquark-case.com/`,
//   },
//   // server: {
//   //   baseUrl: `http://localhost:8002/`,
//   // },
// };

// export default server;

//Production environemt server

const server = {
  ccxtServer: {
    baseUrl: `https://ccxtprod.alphaquark-case.com/`,
  },
  server: {
    baseUrl: `https://server.alphaquark-case.com/`,
  },
  // server: {
  //   baseUrl: `http://localhost:8001/`,
  // },
};

export default server;
