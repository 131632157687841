import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import server from "./serverConfig";

// fetch user at user end
const fetchUser = ({ queryKey }) => {
  const heroId = queryKey[1];
  return axios.get(`${server.server.baseUrl}api/user/getUser/${heroId}`);
};

export const CurrentUserData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["userHero", heroId], fetchUser, {
    initialData: () => {
      const data = queryClient
        .getQueryData("user-heroes")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

// get all csv file for particular admin
const fetchAllCsvFile = ({ queryKey }) => {
  const heroId = queryKey[1];
  return axios.get(`${server.server.baseUrl}api/csv-upload/${heroId}`);
};

export const CurrentAdminAllCsvFileData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["allCsvFile", heroId], fetchAllCsvFile, {
    initialData: () => {
      const data = queryClient
        .getQueryData("user-heroes")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};
