import React from "react";
import DashboardLayout from "../DashboardLayout";
import { User2Icon, UserIcon, UserRoundCogIcon } from "lucide-react";

const ContactAdvisorPage = () => {
  const fullURL = window.location.href;

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center justify-center space-y-6 relative  w-full lg:min-h-screen  bg-[#f9f9f9] py-6">
        {fullURL === "https://altqube.alphaquark-case.com/contact-advisor" ? (
          <div className="flex flex-col space-y-6 items-center px-6 lg:w-[700px]">
            <div className="text-center text-[16px] lg:text-[22px] text-[#000000]/60 lg:leading-[30px] font-medium font-poppins">
              You can contact your advisor on{" "}
              <a href="mailto:csingh@altqube.com">csingh@altqube.com</a> or drop
              a message on +917895547770.
            </div>
            <div className="text-center text-[16px] lg:text-[22px] text-[#000000]/60 lg:leading-[30px] font-medium font-poppins">
              Alternately, you could schedule an appointment by clicking on the
              button below
            </div>
            <a
              target="_blank"
              className="mt-4 px-12 group flex space-x-4 items-center justify-center text-black text-center text-2xl font-bold border-[1px] border-[#000000]/20   py-3 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
              href="https://topmate.io/chanpreet515"
            >
              <span className="text-black group-hover:text-white text-center text-[18px] font-medium  font-poppins">
                Book Appointment
              </span>
            </a>
          </div>
        ) : (
          <div className="flex flex-col space-y-6 items-center px-6 lg:w-[700px]">
            <div className="flex items-center justify-center w-[150px] h-[150px] rounded-full bg-gray-200/70 ">
                <UserRoundCogIcon className="w-[70px] h-[70px]" />
          </div>
            <div className="text-center text-[16px] lg:text-[22px] text-[#000000]/60 lg:leading-[30px] font-medium font-poppins">
              You can contact your advisor on{" "}
              <a
                href="mailto:pratik@alphaquark-case.com"
                target="_blank"
                rel="noreferrer noopener"
                className="font-semibold text-black"
              >
                pratik@alphaquark-case.com
              </a>{" "}
              or drop a message on 
              <span className="mx-1 font-semibold text-black">


              +917895547770.
              </span>
            </div>
            <div className="text-center text-[16px] lg:text-[22px] text-[#000000]/60 lg:leading-[30px] font-medium font-poppins">
              Alternately, you could schedule an appointment by clicking on the
              button below
            </div>
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="mt-8 px-12 group flex space-x-4 items-center justify-center bg-black text-center text-2xl font-bold border-[1px] border-[#000000]/20   py-3 rounded-[6px]  transition-all duration-150 ease-linear cursor-pointer"
              href="https://calendly.com/gopesh-iitk/30min"
            >
              <span className="text-white   text-center text-[18px] font-medium  font-poppins">
                Book Appointment
              </span>
            </a>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ContactAdvisorPage;
