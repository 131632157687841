import React, { useState, useRef } from "react";
import YouTube from "react-youtube";
import { TelegramData } from "./Data";
import { ChevronDown, ChevronUp } from "lucide-react";

const style = {
  subHeading:
    "text-[15px] mb-0.5 leading-[28px] md:text-base md:leading-[36px] text-black font-normal font-poppins ",
  toggleButton:
    "flex items-center justify-between px-4 lg:px-0 mb-2 text-base md:text-lg text-black font-semibold font-poppins leading-[22px] cursor-pointer",
};
const TelegramSteps = ({}) => {
  const playerRef = useRef(null);
  const opts = {
    height: "300",
    width: "630",
    playerVars: {
      autoplay: 0,
    },
  };

  const handlePlayerReady = (event) => {
    // Pause the video when the player is ready
    event.target.pauseVideo();
  };

  const [openIndexes, setOpenIndexes] = useState([]);
  const toggleIndex = (index) => {
    setOpenIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <div className="px-0 lg:px-[28px] pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
      {TelegramData.map((ele, i) => {
        const isOpen = openIndexes.includes(i);
        return (
          <div key={i}>
            <div className="pb-8 rounded-xl">
              <YouTube
                videoId={ele.videoId && ele.videoId}
                className={`videoIframe rounded-xl`}
                opts={opts}
                onReady={handlePlayerReady}
                ref={playerRef}
              />
            </div>
            <div className={style.toggleButton} onClick={() => toggleIndex(i)}>
              {ele.linkContain && ele.linkContain}
              <span className={`lg:hidden`}>
                {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </span>
            </div>
            <div className={`${isOpen ? "block" : "hidden"} lg:block`}>
              <div className={style.subHeading}>{ele.step1 && ele.step1}</div>
              <div className={style.subHeading}>{ele.step2 && ele.step2}</div>
              <div className={style.subHeading}>{ele.step3 && ele.step3}</div>
              <div className={style.subHeading}>{ele.step4 && ele.step4}</div>
              <div className={style.subHeading}>{ele.step5 && ele.step5}</div>
              <div className={style.subHeading}>{ele.step6 && ele.step6}</div>
              <div className={style.subHeading}>{ele.step7 && ele.step7}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TelegramSteps;
