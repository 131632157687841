import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Mail } from "lucide-react";
import server from "../../utils/serverConfig";
import { useNavigate } from "react-router-dom";
import { saveTokenInLocalStorage } from "../../services/AuthService";
import { loginConfirmedAction } from "../../store/actions/AuthActions";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
};

const AdminSignInEmail = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const signInWithEmail = async () => {
    setLoading(true);
    const auth = getAuth();
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      const userDetails = response?._tokenResponse;
      const token = response.user?.accessToken; // Ensure token is available

      if (token) {
        try {
          // Call the login API with email to check access
          const apiResponse = await axios.post(
            `${server.server.baseUrl}api/admin/allowed/login`,
            {
              email: userDetails.email,
            }
          );

          // Assuming success means the user is allowed to proceed
          if (apiResponse.status === 200) {
            // Save token and user details in localStorage
            saveTokenInLocalStorage(userDetails, token);

            // Dispatch login action
            dispatch(loginConfirmedAction({ ...userDetails, token }));

            // Navigate to admin dashboard
            navigate("/admin");
          } else {
            // Handle unexpected responses (shouldn't happen if success is correctly handled)
            throw new Error("Unexpected API response");
          }
        } catch (apiError) {
          // Handle API errors or restricted access
          setLoading(false);
          toast.error(
            `Login is restricted. For advisor onboarding, contact pratik@alphaquark.in.`,
            {
              duration: 5000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "560px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
        }
      } else {
        // If token is not available, this condition is redundant but safe to have
        throw new Error("Token not found");
      }
    } catch (err) {
      setLoading(false);
      toast.error(`Credential is incorrect. Please check your credentials.`, {
        duration: 5000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "560px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  return (
    <div className="max-w-screen-xl w-full bg-white relative rounded-lg font-Poppins">
      <div className={style.inputStartDiv}>
        <input
          id="email"
          type="email"
          placeholder="Enter your email"
          className={`${style.inputBox}`}
          // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label
          htmlFor="email"
          className={`${style.firstHeading} ${style.labelFloat}`}
        >
          Enter your email
        </label>
      </div>
      <div className={style.inputStartDiv}>
        <input
          id="password"
          type="password"
          placeholder="Enter your password"
          className={`${style.inputBox}`}
          // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label
          htmlFor="password"
          className={`${style.firstHeading} ${style.labelFloat}`}
        >
          Enter your password
        </label>
      </div>

      <div className="w-full flex justify-start pb-2 mt-6 mb-2">
        <div
          className="ml-1 flex text-[15px] font-poppins flex-row items-center justify-center rounded-lg  text-[#000000]/60 font-medium cursor-pointer"
          onClick={() => props.setShowForgotPassord(true)}
        >
          Forgot your password?
        </div>
      </div>
      <div>
        {" "}
        <button
          onClick={signInWithEmail}
          disabled={!email || !password}
          className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed flex flex-row items-center justify-center py-2 w-full mb-3 rounded-lg bg-black/100 text-white font-medium hover:bg-black/90 ease-linear duration-150"
        >
          <div className="ml-4 text-white font-medium text-lg font-poppins ">
            {loading === true ? (
              <span className="flex flex-row justify-center ">
                <svg
                  className="h-10 w-10 text-[#ffffff] animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            ) : (
              <div className="flex flex-row items-center justify-center">
                <Mail size={25} className="mr-2" />
                Sign In with Email
              </div>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default AdminSignInEmail;
