import React, { useEffect, useState } from "react";
import server from "../../utils/serverConfig";
import { useParams } from "react-router-dom";
import axios from "axios";
import AdminDashboardLayout from "../AdminDashboardLayout";
import ClientListNewDetailsTable from "./ClientListNewDetailsTable";

const ClientListDetailsNewPage = () => {
  const { groupName } = useParams();

  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString
    ? JSON.parse(adminDetailsString)
    : null;
  const adminEmail = adminDetails ? adminDetails.email : null;

  const [data, setData] = useState();
  const [allClientsDataByGroup, setAllClientsDataByGroup] = useState([]);
  const [isClientDataLoading, setIsClientDataLoading] = useState(true);

  const getAllClientsData = async (userId) => {
    try {
      setIsClientDataLoading(true);
      const response = await fetch(
        `${server.server.baseUrl}api/all-clients/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch cleint data");
      }

      const data = await response.json();

      // // Assuming the group names are in the `data` array
      const clientsData = data.data;

      const clientDataByGroup = clientsData.filter((client) =>
        client.groups.some(
          (group) => group.toLowerCase() === groupName.toLowerCase()
        )
      );

      setAllClientsDataByGroup(clientDataByGroup);
      setIsClientDataLoading(false);
    } catch (error) {
      console.error("Error fetching Client data:", error);
      setIsClientDataLoading(false);
      return [];
    }
  };

  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/admin/${adminEmail}`)
      .then((res) => {
        setData(res.data.data);
        getAllClientsData(res.data.data?._id);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDeatils();
  }, [adminEmail]);

  const userId = data && data?._id;

  const advisorName = data && data?.advisorName;
  const email_password = data && data?.email_password;

  return (
    <AdminDashboardLayout>
      <ClientListNewDetailsTable
        userId={userId && userId}
        groupName={groupName}
        adminEmail={adminEmail}
        advisorName={advisorName}
        email_password={email_password}
        allClientsDataByGroup={allClientsDataByGroup}
        isClientDataLoading={isClientDataLoading}
        getAllClientsData={getAllClientsData}
      />
    </AdminDashboardLayout>
  );
};

export default ClientListDetailsNewPage;
