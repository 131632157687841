import React, { useState, useEffect, useCallback } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { auth } from "../firebase";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
};

const ForgotPassword = ({ setShowForgotPassord, ...props }) => {
  const [email, setEmail] = useState("");

  const [error, setError] = useState(null);

  const [showComponent, setShowComponent] = useState(false);
  const [loading, setLoading] = useState(false);
  const handlePasswordReset = (e) => {
    setLoading(true);
    e.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setError(null);
        setShowComponent(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setShowComponent(false);
        setLoading(false);
      });
  };

  const handleShowComponent = useCallback(() => {
    setShowComponent(false);
    setShowForgotPassord(false);
  }, [setShowForgotPassord]);

  useEffect(() => {
    if (showComponent) {
      const toRef = setTimeout(() => {
        handleShowComponent();
      }, 3000);
      return () => clearTimeout(toRef);
    }
  }, [showComponent, handleShowComponent]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return (
    <div className=" bg-white p-4 relative rounded-lg font-Poppins ">
      <button
        onClick={() => setShowForgotPassord(false)}
        className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
      >
        <div>
          <MdOutlineArrowBack size={25} />
        </div>
        <div className="hidden md:flex ">Back</div>
      </button>

      <div className="pt-8 pb-4">
        <div className="font-poppins md:text-xl text-base font-semibold pb-3">
          Forgot Password
        </div>

        {/* {error && <p>{error}</p>} */}

        <div className={style.inputStartDiv}>
          <input
            id="email"
            type="email"
            placeholder="Enter your email"
            className={`${style.inputBox}`}
            // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label
            htmlFor="email"
            className={`${style.firstHeading} ${style.labelFloat}`}
          >
            Enter your email
          </label>
        </div>
        {!error && (
          <div className="max-w-sm  bg-white flex items-center justify-center p-2 mt-0">
            <div className="text-[#D1433A] text-base text-center font-semibold ml-2 -mt-0.5">
              {error}
            </div>
          </div>
        )}

        {showComponent === true && (
          <p className="py-4 text-[#16A085] text-base font-bold text-center">
            Password reset email sent successfully.
          </p>
        )}
        <div className="mt-4">
          <button
            onClick={handlePasswordReset}
            disabled={!email || !emailRegex.test(email)}
            className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed flex flex-row items-center justify-center py-2 w-full mb-3 rounded-lg bg-black/100 text-white font-medium hover:bg-black/90 ease-linear duration-150"
          >
            <div className=" text-white font-medium text-lg font-poppins ">
              {loading === true ? (
                <span className="flex flex-row justify-center ">
                  <svg
                    className="h-7 w-7 text-[#ffffff] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              ) : (
                "Reset Password"
              )}
            </div>
          </button>
        </div>
      </div>

      {/* <div className="bg-white flex p-2 mt-2 ">
        <div className="text-white text-sm font-normal ml-2 -mt-0.5">
          Added New User Here through this section check email first if mail not
          there
        </div>
      </div> */}
    </div>
  );
};

export default ForgotPassword;
