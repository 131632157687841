import React, { useState, useCallback, useEffect, useRef } from "react";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.min.css";
import { PlusIcon } from "lucide-react";

// Register all necessary modules
import { registerAllModules } from "handsontable/registry";
import LoadingSpinner from "../../components/LoadingSpinner";
import moment from "moment";
import server from "../../utils/serverConfig";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { XIcon, Plus, Trash2Icon } from "lucide-react";
import { debounce } from "lodash";
import { io } from "socket.io-client";

registerAllModules();

// Custom Textarea Editor for the Rationale field
class TextareaEditor extends Handsontable.editors.TextEditor {
  createElements() {
    super.createElements();

    this.TEXTAREA = document.createElement("textarea");
    this.TEXTAREA.style.width = "100%";
    this.TEXTAREA.style.height = "50px"; // Fixed height
    this.TEXTAREA.style.minHeight = "50px"; // Ensure minimum height
    this.TEXTAREA.style.maxHeight = "60px"; // Optional: Set max height to allow scrolling
    this.TEXTAREA.style.border = "1px solid #ccc";
    this.TEXTAREA.style.padding = "4px";
    this.TEXTAREA.style.resize = "none"; // Disable resize
    this.TEXTAREA.style.overflowY = "auto"; // Enable vertical scrolling

    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
  }

  getValue() {
    return this.TEXTAREA.value;
  }

  setValue(value) {
    this.TEXTAREA.value = value;
  }
}

const rationaleRenderer = (
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) => {
  const input = document.createElement("textarea");
  input.value = value;
  input.readOnly = false; // Make the textarea read-only
  input.style.width = "100%";
  input.style.height = "50px"; // Set desired height
  input.style.resize = "none"; // Disable resize
  input.style.border = "none";
  input.style.outline = "none";
  input.style.padding = "4px";
  td.style.verticalAlign = "top";
  td.innerHTML = ""; // Clear existing content
  td.appendChild(input); // Append the textarea to the cell
};

const FloatAdviceTable = () => {
  const hotTableRef = useRef(null);

  const user = "AlphaQuark";
  const advisorName = "AlphaQuark";
  const adminEmail = "test@gmail.com";
  const email_password = "test@123";

  const [symbols, setSymbols] = useState(["aapl", "msft", "tsla"]);

  const [adviceEntries, setAdviceEntries] = useState([
    {
      email: "",
      symbol: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: 0,
      segment: "EQUITY",
      price: 0,
      type: "",
      ltp: 0,
      totalValue: "",
      adviceLower: 0,
      adviceHigher: 0,
      rationale:
        "This recommendation is based on a comprehensive analysis of the company's growth potential and value metrics. This recommendation also accounts for potential future risks, ensuring a balanced approach to maximizing returns while mitigating uncertainties. Please contact your advisor for any queries.",
    },
  ]);

  const [ltp, setLtp] = useState([]);

  // Example of a placeholder function for getLTPForSymbol
  // const getLTPForSymbol = (symbol) => {
  //   // Replace with actual logic to fetch LTP for the given symbol
  //   const ltpData = {
  //     AAPL: 150,
  //     GOOGL: 2800,
  //     AMZN: 3400,
  //     // Add more symbol and LTP pairs as needed
  //   };

  //   return ltpData[symbol] || 0; // Return 0 if symbol is not found
  // };

  const getLTPForSymbol = (symbol) => {
    // Remove suffixes from the input symbol

    console.log("symbol recieveing", symbol);
    const ltpOne = ltp.find((item) => {
      return item.tradingSymbol === symbol;
    })?.lastPrice;

    console.log("ltpone", ltpOne);
    return ltpOne ? ltpOne.toFixed(2) : "-";
  };

  const updateRow = (row, field, value) => {
    setAdviceEntries(async (prevData) => {
      const newData = [...prevData];
      newData[row] = { ...newData[row], [field]: value };

      console.log("value issss", value);

      // Update LTP and totalValue based on orderType, quantity, and price
      if (field === "symbol") {
        console.log("query", value);
        const data = await fetchSymbols(value);
        console.log("data", data);
        setSymbols(data);

        newData[row].ltp = getLTPForSymbol(value);
        // If the order type is "MARKET", automatically set price to LTP
        if (newData[row].orderType === "MARKET") {
          newData[row].price = newData[row].ltp;
        }
      }

      if (field === "orderType") {
        // Set price to LTP if order type is "MARKET"
        newData[row].price =
          value === "MARKET" ? newData[row].ltp : newData[row].price;
      }

      // Calculate total value
      newData[row].totalValue =
        newData[row].orderType === "MARKET" && newData[row].quantity > 0
          ? (newData[row].ltp * newData[row].quantity).toFixed(2)
          : newData[row].orderType !== "MARKET" &&
            newData[row].quantity > 0 &&
            newData[row].price > 0
          ? (newData[row].quantity * newData[row].price).toFixed(2)
          : "";

      return newData;
    });
  };

  const handleDataChange = (changes, source) => {
    // console.log("changes", changes);
    // console.log("source", source);

    // const newSymbols = fetchSymbols(value)

    // console.log("new data",newSymbols)
    if (changes && source !== "loadData") {
      changes.forEach(([row, field, prev, next]) => {
        const prevValue = prev === undefined || prev === null ? "" : prev;
        const nextValue = next === undefined || next === null ? "" : next;

        if (prevValue.toString() !== nextValue.toString()) {
          updateRow(row, field, nextValue);
        }
      });
    }
  };

  const addNewRow = () => {
    setAdviceEntries((prevData) => [
      ...prevData,
      {
        email: "",
        symbol: "",
        orderType: "MARKET",
        productType: "DELIVERY",
        quantity: 0,
        segment: "EQUITY",
        price: 0,
        type: "",
        ltp: 0,
        totalValue: "",
        adviceLower: 0,
        adviceHigher: 0,
        symbols: ["AAPL", "GOOGL", "AMZN"], // Example symbols
        rationale:
          "This recommendation is based on a comprehensive analysis of the company's growth potential and value metrics. This recommendation also accounts for potential future risks, ensuring a balanced approach to maximizing returns while mitigating uncertainties. Please contact your advisor for any queries.",
      },
    ]);
  };

  const deleteRow = (index) => {
    setAdviceEntries((prevData) => prevData.filter((_, i) => i !== index));
  };

  const deleteRenderer = (
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) => {
    const trashButton = document.createElement("button");
    trashButton.style.border = "none";
    trashButton.style.background = "none";
    trashButton.style.paddingTop = "10px";
    trashButton.style.marginLeft = "3px";
    trashButton.style.cursor = "pointer";

    // Only show delete button if there's more than one row
    if (adviceEntries.length > 1) {
      trashButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>`;
      trashButton.onclick = () => deleteRow(row);
    }

    td.innerHTML = ""; // Clear existing content
    td.appendChild(trashButton);
  };

  const [loading, setLoading] = useState(false);

  const debouncedFetchSymbols = useCallback(
    debounce((index, value) => {
      fetchSymbols(index, value);
    }, 300),
    []
  );

  const todaydate = new Date();

  const fetchSymbols = async (inputValue) => {
    const data = JSON.stringify({
      symbol: inputValue,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${server.ccxtServer.baseUrl}get-symbol-name-exchange`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      return response.data.match;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (adviceEntries && adviceEntries.length > 0) {
      getCurrentPrice();
    }
  }, [adviceEntries]);

  const isValid = adviceEntries.every(
    (entry) =>
      entry.symbol &&
      entry.exchange &&
      entry.orderType &&
      entry.productType &&
      entry.quantity > 0 &&
      entry.segment &&
      entry.type &&
      entry.rationale
  );

  const [socket, setSocket] = useState(null);

  const getCurrentPrice = () => {
    if (!adviceEntries || adviceEntries.length === 0) {
      // console.log("No trades available to fetch prices for");
      return;
    }

    const uniqueSymbols = new Set();
    const symbolsToSubscribe = adviceEntries
      .map((trade) => ({
        exchange: trade.exchange,
        tradingSymbol: trade.symbol,
      }))
      .filter((order) => {
        if (uniqueSymbols.has(order.tradingSymbol)) {
          return false;
        } else {
          uniqueSymbols.add(order.tradingSymbol);
          return true;
        }
      });

    symbolsToSubscribe.forEach((symbol) => {
      const data = {
        symbol: symbol.tradingSymbol,
        exchange: symbol.exchange,
      };

      axios
        .post("https://ccxt.alphaquark-case.com/websocket/subscribe", data)
        .then((response) => {
          // console.log(`Subscribed to ${symbol.tradingSymbol}:`, response.data);
        })
        .catch((error) => {
          // console.error(`Error subscribing to ${symbol.tradingSymbol}:`, error);
        });
    });
  };

  useEffect(() => {
    // Initialize WebSocket connection
    const newSocket = io("wss://ccxt.alphaquark-case.com", {
      transports: ["websocket"],
      query: { EIO: "4" },
    });

    newSocket.on("connect", () => {
      // console.log("Connected to Socket.IO server");
    });

    newSocket.on("market_data", (data) => {
      setLtp((prev) => {
        const index = prev.findIndex(
          (item) => item.tradingSymbol === data.stockSymbol
        );
        if (index !== -1) {
          const newLtp = [...prev];
          newLtp[index] = {
            ...newLtp[index],
            lastPrice: data.last_traded_price,
          };
          return newLtp;
        } else {
          const updatedLtp = [
            ...prev,
            {
              tradingSymbol: data.stockSymbol,
              lastPrice: data.last_traded_price,
            },
          ];
          return updatedLtp;
        }
      });
    });

    newSocket.on("disconnect", () => {
      // console.log("Disconnected from Socket.IO server");
    });

    const pingInterval = setInterval(() => {
      if (newSocket.connected) {
        newSocket.emit("ping");
      }
    }, 20000);

    setSocket(newSocket);

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  const handleSendAdvice = () => {
    // setLoading(true);

    const adviceData = adviceEntries.map((entry) => ({
      email: entry.email,
      trade_given_by: adminEmail,
      email_password: email_password,
      trade_given_by_fileName: "",
      advisor_name: advisorName,
      Symbol: entry.symbol,
      Exchange: entry.exchange,
      Type: entry.type,
      OrderType: entry.orderType,
      ProductType: entry.productType,
      Segment: entry.segment,
      Price: entry.orderType === "MARKET" ? 0 : entry.price,
      date: moment(todaydate).format(),
      Quantity: entry.quantity,
      Advised_Range_Lower: entry.adviceLower,
      Advised_Range_Higher: entry.adviceHigher,
      rationale: entry?.rationale,
      price_when_send_advice: getLTPForSymbol(entry?.symbol),
    }));

    console.log("all advices", adviceData);
  };

  return (
    <>
      <div className="flex flex-col border-[1px] border-[#000000]/10 h-full">
        <div className="flex flex-col overflow-auto custom-scroll">
          <HotTable
            licenseKey="non-commercial-and-evaluation"
            ref={hotTableRef}
            data={adviceEntries}
            rowHeaders={true}
            colHeaders={true}
            columnSorting={{ sortEmptyCells: true }}
            rowHeights={50}
            modifyColumnHeaderHeight={100}
            height={600}
            className="overflow-auto"
            columns={[
              {
                data: "delete",
                title: " ",
                renderer: deleteRenderer, // Updated renderer
                width: 40,
                readOnly: true,
              },
              {
                data: "email",
                title: "Email",
                type: "text",
                width: 150,
                placeholder: "Enter email",
              },

              {
                data: "symbol",
                title: "Symbol",
                type: "dropdown",
                width: 150,
                placeholder: "Search symbol",
                source: symbols,
              },
              {
                data: "orderType",
                title: "Order Type",
                type: "dropdown",
                width: 120,
                source: ["MARKET", "LIMIT", "STOP"],
              },
              {
                data: "productType",
                title: "Product Type",
                type: "dropdown",
                width: 120,
                source: ["DELIVERY", "INTRADAY"],
              },
              {
                data: "quantity",
                title: "Quantity",
                type: "numeric",
                width: 80,
                format: "0,0",
              },
              {
                data: "segment",
                title: "Segment",
                type: "dropdown",
                width: 80,
                source: ["EQUITY"],
              },
              {
                data: "price",
                title: "Price",
                type: "numeric",
                width: 80,
                format: "0,0",
                readOnly: (row) => adviceEntries[row]?.orderType === "MARKET",
              },
              {
                data: "ltp",
                title: "LTP",
                type: "numeric",
                width: 80,
                format: "0,0",
                readOnly: true,
              },
              {
                data: "totalValue",
                title: "Total Value",
                type: "numeric",
                width: 100,
                format: "0,0",
                readOnly: true,
              },
              {
                data: "adviceLower",
                title: "Advice Lower",
                type: "numeric",
                width: 100,
                format: "0,0",
              },
              {
                data: "adviceHigher",
                title: "Advice Higher",
                type: "numeric",
                width: 100,
                format: "0,0",
              },
              {
                data: "rationale",
                title: "Rationale",
                type: "text",
                width: 220,
                renderer: rationaleRenderer,
                editor: TextareaEditor,
              },
            ]}
            afterChange={handleDataChange}
            beforeChange={(changes, source) => {
              if (source === "loadData") return false;
            }}
          />
        </div>

        <div className=" border-[1px] border-t-[#000000]/10 flex items-center justify-between py-3  px-4 w-full ">
          <div
            o
            onClick={addNewRow}
            className="flex  items-center bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[14px] font-medium font-poppins rounded-md px-3 py-3 cursor-pointer"
          >
            <span>
              <PlusIcon className="text-black h-5 w-5 mr-1" />
            </span>
            Add Row
          </div>

          <div className="flex flex-row justify-end items-center">
            <button
              onClick={handleSendAdvice}
              //   disabled={!isValid}
              className="md:w-[210px] disabled:bg-[#000000]/20 text-center bg-black disabled:cursor-not-allowed text-white text-[16px]  md:text-[18px] font-medium font-poppins rounded-md px-6 py-2 md:px-10 md:py-3 cursor-pointer hover:scale-[1.02] transition-all duration-150 ease-linear"
            >
              {loading === true ? <LoadingSpinner /> : " Send Advice"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FloatAdviceTable;
