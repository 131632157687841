import React, { useState, useEffect } from "react";
import VolatilityIcon from "../../assests/volatility.svg";
import Alpha100 from "../../assests/alpha-100.png";
import ChartData from "../../assests/chart_data.png";
import { Pie, PieChart } from "recharts";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";

import {
  CandlestickChartIcon,
  PencilIcon,
  Share2Icon,
  TimerIcon,
  TrendingUpIcon,
} from "lucide-react";
import RebalanceModal from "./RebalanceModal";
import RebalanceTimeLineModal from "./RebalanceTimeLineModal";
import server from "../../utils/serverConfig";

const style = {
  selected:
    "flex items-center text-[16px] md:text-[18px]  font-sans text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[16px] md:text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const chartData = [
  { shares: "microsoft", value: 45, fill: "#f59e0b" },
  { shares: "reliance", value: 10, fill: "#f43f5e" },
  { shares: "larsen", value: 20, fill: "#d946ef" },
  { shares: "deepak", value: 25, fill: "#f97316" },
];

const chartConfig = {
  value: {
    label: "Equity Distribution",
  },
  microsoft: {
    label: "Microsoft",
    color: "#f59e0b",
  },
  reliance: {
    label: "Reliance",
    color: "#f43f5e",
  },
  larsen: {
    label: "Larsen & Tourbo",
    color: "#d946ef",
  },
  deepak: {
    label: "Deepak Nitride",
    color: "#f97316",
  },
};

const StrategyDetails = ({ fileName }) => {
  const [selectedDataTab, setSelectedDataTab] = useState("performance");
  const [showRebalanceModal, setShowRebalanceModal] = useState(false);
  const [showRebalanceTimelineModal, setShowRebalanceTimelineModal] =
    useState(false);

  const openRebalanceModal = () => {
    setShowRebalanceModal(true);
  };

  const closeRebalanceModal = () => {
    setShowRebalanceModal(false);
  };

  const openRebalanceTimelineModal = () => {
    setShowRebalanceTimelineModal(true);
  };

  const closeRebalanceTimelineModal = () => {
    setShowRebalanceTimelineModal(false);
  };

  const [strategyDetails, setStrategyDetails] = useState(null);
  const [latestRebalance, setLatestRebalance] = useState(null);
  const getStrategyDetails = () => {
    if (fileName) {
      axios
        .get(
          `${
            server.server.baseUrl
          }api/model-portfolio/portfolios/strategy/${fileName?.replaceAll(
            /_/g,
            " "
          )}`
        )
        .then((res) => {
          const portfolioData = res.data;
          setStrategyDetails(portfolioData);
          if (
            portfolioData &&
            portfolioData.model &&
            portfolioData.model.rebalanceHistory.length > 0
          ) {
            const latest = portfolioData.model.rebalanceHistory.sort(
              (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
            )[0];
            setLatestRebalance(latest);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getStrategyDetails();
  }, [fileName]);

  return (
    <div className="flex flex-col max-h-[calc(100vh-60px)]  lg:min-h-screen  bg-[#F9F9F9] ">
      {/* Bottom Fixed Add New rebalance Button for Mobile View  */}
      <Toaster position="top-center" reverseOrder={true} />
      <div className="w-full grid grid-cols-12 h-[calc(100vh-120px)] overflow-y-auto lg:min-h-full">
        {/* Left  Side Strategy Details Part */}
        <div className="col-span-12 px-4 lg:col-span-9 lg:px-[60px]">
          <div className="flex flex-col space-y-6 py-10 ">
            <div className="flex space-x-6">
              {strategyDetails?.image ? (
                <div className="flex items-center justify-center">
                  <img
                    src={`${server.server.baseUrl}${strategyDetails.image}`}
                    alt={strategyDetails?.modal_name}
                    className="h-[50px] w-[50px] rounded-md"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src={Alpha100}
                    alt="Alpha 100 Strategy"
                    className="h-[50px] w-[50px] rounded-md"
                  />
                </div>
              )}

              <div className="flex justify-center flex-col">
                <div className="flex items-center space-x-4">
                  <h3 className="text-[18px] font-sans font-bold capitalize text-[#000000]">
                    {fileName?.replaceAll(/_/g, " ")}
                  </h3>
                </div>
              </div>
            </div>

            <p className="text-[14px] font-normal font-poppins text-[#000000]/60">
              {strategyDetails?.overView}
            </p>
          </div>

          <div className="flex flex-col space-y-6 pb-8 lg:hidden">
            <div className="flex justify-evenly  divide-x-2 bg-[#E4FDD5]/50 ">
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  CAGR
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +186%
                </p>
              </div>

              <div className="flex flex-col space-y-1 items-center  justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  Returns (2 yrs)
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +400%
                </p>
              </div>
            </div>

            <div className="w-full flex flex-col ">
              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <div className="flex items-center justify-center">
                  <img src={VolatilityIcon} className="h-[26px] w-[26px]" />
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Volatility :
                  <p className="ml-2 text-[14px] text-[#000000] font-poppins font-medium ">
                    Low
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <div className="flex items-center justify-center text-[12px] pt-0.5 rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[24px] w-[24px] ">
                  10
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Strategy Score
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <TrendingUpIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Rebalance Frequency :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    Weekly
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <TimerIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Duration :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    Short Terms
                  </p>
                </div>
              </div>

              <div className="flex items-center border-none py-4 space-x-3">
                <CandlestickChartIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  No. of Trades :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    15K Trades
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <div className="flex items-center  justify-between border-b-[1px] border-[#000000]/10">
              <div className="flex  space-x-6 ">
                <div
                  className={`
              ${
                selectedDataTab === "performance"
                  ? style.selected
                  : style.unselected
              } flex items-center justify-center md:w-[120px] py-2 lg:py-1`}
                  onClick={() => {
                    setSelectedDataTab("performance");
                  }}
                >
                  Performance
                </div>
                <div
                  className={`
                ${
                  selectedDataTab === "distribution"
                    ? style.selected
                    : style.unselected
                } flex items-center justify-center md:w-[120px] py-2 lg:py-1`}
                  onClick={() => {
                    setSelectedDataTab("distribution");
                  }}
                >
                  Distribution
                </div>
              </div>
              <Share2Icon className="h-5 w-5 text-[#000000] " />
            </div>

            {selectedDataTab === "performance" && (
              <div className="flex items-center h-full w-full">
                <img src={ChartData} alt="Alpha 100 Performance Chart" />
              </div>
            )}

            {selectedDataTab === "distribution" && (
              <div className="flex flex-col space-y-6">
                <div className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                  Check Latest Rebalance Updates issued by the Manager.
                  <span
                    onClick={openRebalanceTimelineModal}
                    className="ml-2 cursor-pointer text-[14px] text-blue-500 font-normal font-poppins underline"
                  >
                    View Rebalance History
                  </span>
                </div>

                <div className="grid grid-cols-2 gap-y-5 md:gap-y-0  md:grid-cols-3 lg:w-[656px] lg:divide-x-2 divide-[#000000]/10 bg-[#f1f0f0] rounded-[4px] py-4 lg:px-0">
                  <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                    <h4 className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                      Last Rebalance
                    </h4>
                    <p className="text-[16px] text-[#000000] font-medium font-poppins">
                      {moment(strategyDetails?.last_updated).format(
                        "MMM DD, YYYY"
                      )}
                    </p>
                  </div>
                  <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                    <h4 className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                      Next Rebalance
                    </h4>
                    <p className="text-[16px] text-[#000000] font-medium font-poppins">
                      {moment(strategyDetails?.nextRebalanceDate).format(
                        "MMM DD, YYYY"
                      )}
                    </p>
                  </div>
                  <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                    <h4 className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                      Rebalance Frequency
                    </h4>
                    <p className="text-[16px] text-[#000000] font-medium font-poppins">
                      {strategyDetails?.frequency}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row lg:space-x-8 ">
                  <div className="flex flex-col lg:w-[400px]">
                    <div className="grid grid-cols-5 border-b-[1px] border-[#000000]/10 py-3 px-4 ">
                      <div className="col-span-3 text-[14px] text-[#000000]/60 font-normal font-poppins">
                        Equity
                      </div>
                      <div className="flex justify-end col-span-2 text-[14px] text-[#000000]/60 font-normal font-poppins">
                        Weightage (%)
                      </div>
                    </div>
                    {latestRebalance?.adviceEntries?.map((ele, i) => {
                      return (
                        <div
                          className="relative grid grid-cols-5 border-b-[1px] border-b-[#000000]/10 border-l-[4px]  border-l-amber-500   py-4 px-8"
                          key={i}
                        >
                          <div className="col-span-3 text-[15px] text-[#000000]/85 font-normal font-poppins">
                            {ele.symbol}
                          </div>
                          <div className="flex justify-end col-span-2 text-[15px] text-[#000000]/85 font-normal font-poppins">
                            {ele.value * 100}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <ChartContainer
                    config={latestRebalance?.adviceEntries}
                    className="mx-auto aspect-square max-h-[300px] h-[280px] "
                  >
                    <PieChart>
                      <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent hideLabel />}
                      />
                      <Pie
                        data={latestRebalance?.adviceEntries}
                        dataKey="value"
                        nameKey="shares"
                      />
                    </PieChart>
                  </ChartContainer>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-6 py-10 ">
            <div className=" text-[18px] font-semibold font-sans text-[#000000]">
              Why this Strategy?
            </div>

            <p className="text-[14px] font-normal font-poppins text-[#000000]/60">
              {strategyDetails?.whyThisStrategy}
            </p>
          </div>
        </div>

        {/* Right Side Metrics Part */}

        <div className="hidden lg:block col-span-3">
          <div className="flex flex-col space-y-6 px-6 py-10">
            <button
              onClick={openRebalanceModal}
              className="w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6"
            >
              Add New Rebalance
            </button>

            <div className="flex justify-evenly  divide-x-2 bg-[#E4FDD5]/50 ">
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  CAGR
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +186%
                </p>
              </div>

              <div className="flex flex-col space-y-1 items-center  justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  Returns (2 yrs)
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +400%
                </p>
              </div>
            </div>

            <div className="w-full flex flex-col py-6">
              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <div className="flex items-center justify-center">
                  <img src={VolatilityIcon} className="h-[26px] w-[26px]" />
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Volatility :
                  <p className="ml-2 text-[14px] text-[#000000] font-poppins font-medium ">
                    Low
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <div className="flex items-center justify-center text-[14px] pt-0.5 rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[30px] w-[30px] ">
                  10
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Strategy Score
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <TrendingUpIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Rebalance Frequency :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    Weekly
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <TimerIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Duration :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    Short Terms
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <CandlestickChartIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  No. of Trades :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    15K Trades
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* RebalanceModal  */}
        {showRebalanceModal && (
          <RebalanceModal
            closeRebalanceModal={closeRebalanceModal}
            fileName={fileName}
            data={latestRebalance?.adviceEntries}
            strategyDetails={strategyDetails}
            server={server}
            getStrategyDetails={getStrategyDetails}
          />
        )}
        {showRebalanceTimelineModal && (
          <RebalanceTimeLineModal
            closeRebalanceTimelineModal={closeRebalanceTimelineModal}
          />
        )}
      </div>

      <div className="lg:hidden flex items-center w-full h-[80px] shadow-[0px_-4px_4px_0px_#0000000f] px-4">
        <button
          onClick={openRebalanceModal}
          className=" w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6"
        >
          Add New Rebalance
        </button>
      </div>
    </div>
  );
};

export default StrategyDetails;
