import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import AdminDashboardLayout from "../AdminDashboardLayout";
import StrategyDetails from "./StrategyDetails";
import server from "../../utils/serverConfig";

const StrategyDetailsPage = () => {
  const { fileName } = useParams();
  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString
    ? JSON.parse(adminDetailsString)
    : null;
  const adminEmail = adminDetails ? adminDetails.email : null;

  const [data, setData] = useState();

  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/admin/${adminEmail}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDeatils();
  }, [adminEmail]);

  const userId = data && data?._id;
  const advisorName = data && data?.advisorName;
  return (
    <AdminDashboardLayout>
      <StrategyDetails
        fileName={fileName}
        userId={userId && userId}
        adminEmail={adminEmail && adminEmail}
        advisorName={advisorName && advisorName}
      />
    </AdminDashboardLayout>
  );
};

export default StrategyDetailsPage;
