import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { XIcon } from "lucide-react";
import axios from "axios";
import { BiSolidOffer } from "react-icons/bi";
import { TiDocumentText } from "react-icons/ti";
import { IoIosArrowForward } from "react-icons/io";
import CryptoJS from "crypto-js";

import server from "../../utils/serverConfig";
import Checked from "../../assests/checked.svg";
import LoadingSpinner from "../../components/LoadingSpinner";
import IsMarketHours from "../../utils/isMarketHours";

const style = {
  offerSelected:
    "py-4 px-4 border-2 border-[#008000] rounded-md cursor-pointer",
  offerNotSelected:
    "py-4 px-4 border-2 border-[#000000]/20 rounded-md cursor-pointer",
};
const UserStrategySubscribeModal = ({
  fileName,
  userEmail,
  getStrategyDetails,
  strategyDetails,
  setOpenSubscribeModel,
  latestRebalance,
  userDetails,
  setOpenSucessModal,
  setOrderPlacementResponse,
  getLTPForSymbol,
  setBrokerModel,
}) => {
  const [selectedOffer, setSelectedOffer] = useState("3 Months");

  const [openStrategy, setOpenStrategy] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const matchCode = () => {
    if (couponCode.toUpperCase() === "ALPHA100") {
      setOpenStrategy(true);
    }
  };

  const [confirmOrder, setConfirmOrder] = useState(false);

  const clientCode = userDetails && userDetails?.clientCode;
  const apiKey = userDetails && userDetails?.apiKey;
  const jwtToken = userDetails && userDetails?.jwtToken;
  const secretKey = userDetails && userDetails?.secretKey;
  const broker = userDetails && userDetails?.user_broker;

  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const convertResponse = (rebalanceData, totalInvestmentValue) => {
    return rebalanceData.map((item) => {
      const rawQuantity =
        (totalInvestmentValue * item.value) / getLTPForSymbol(item.symbol);
      const quantity =
        rawQuantity % 1 >= 0.8
          ? Math.ceil(rawQuantity)
          : Math.floor(rawQuantity);
      return {
        exchange: item.exchange,
        orderType: "MARKET",
        price: 0,
        priority: 1,
        productType: "DELIVERY",
        quantity: quantity, // assuming you want to round down the quantity to the nearest whole number
        segment: "EQUITY",
        tradingSymbol: item.symbol,
        transactionType: "BUY", // Assuming all are buy orders
        user_broker: broker, // Assuming user_broker remains "Zerodha"
        user_email: userEmail, // Assuming user_email is static
      };
    });
  };

  const stockDetails = convertResponse(
    latestRebalance?.adviceEntries,
    latestRebalance?.totalInvestmentvalue
  );

  const [funds, setFunds] = useState({});

  const getAllFunds = () => {
    if (broker === "IIFL Securities") {
      if (clientCode) {
        let data = JSON.stringify({
          clientCode: clientCode,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}iifl/margin`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "ICICI Direct") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          sessionToken: jwtToken,
          secretKey: checkValidApiAnSecret(secretKey),
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}icici/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Upstox") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          accessToken: jwtToken,
          apiSecret: checkValidApiAnSecret(secretKey),
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}upstox/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Angel One") {
      if (apiKey && jwtToken) {
        let data = JSON.stringify({
          apiKey: apiKey,
          jwtToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Zerodha") {
      if (jwtToken) {
        let data = JSON.stringify({
          apiKey: "b0g1r806oitsamoe",
          accessToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}zerodha/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    getAllFunds();
  }, [userDetails]);
  const subscribeModelPortfolio = () => {
    let data = JSON.stringify({
      email: userEmail,
    });

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/model-portfolio/subscribe-strategy/${strategyDetails?._id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setOpenSubscribeModel(false);
        getStrategyDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const placeOrder = () => {
    const isMarketHours = IsMarketHours();
    setLoading(true);
    if (broker === undefined) {
      setBrokerModel(true);
      setLoading(false);
    } else if (
      !funds?.availablecash ||
      (!funds && Object.keys(funds).length === 0)
    ) {
    } else if (!isMarketHours) {
      toast.error("Orders cannot be placed after Market hours.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      return;
    } else {
      if (broker === "IIFL Securities") {
        let data = JSON.stringify({
          clientCode: clientCode,
          trades: stockDetails,
        });
        let orderConfig = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}iifl/process-trades`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(orderConfig)
          .then((response) => {
            // console.log("res--------", response);
            setLoading(false);
            setOpenSucessModal(true);
            setOrderPlacementResponse(response.data);
            setOpenSubscribeModel(false);
            subscribeModelPortfolio();
          })
          .catch((error) => {
            console.error(`Error placing order for stock `, error);
            setLoading(false);
            toast.error("Something went wrong. Please try again later", {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            });
            // Handle error for individual stock
          });
      } else if (broker === "ICICI Direct") {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          secretKey: checkValidApiAnSecret(secretKey),
          jwtToken: jwtToken,
          trades: stockDetails,
        });
        let orderConfig = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}icici/process-trades`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(orderConfig)
          .then((response) => {
            // console.log("res=======", response);
            setLoading(false);
            setOpenSucessModal(true);
            setOrderPlacementResponse(response.data);
            setOpenSubscribeModel(false);
            subscribeModelPortfolio();
          })
          .catch((error) => {
            console.log("err", error);
            toast.error(
              "There was an issue in placing the trade, please try again after sometime or contact your advisor",
              {
                duration: 6000,
                style: {
                  background: "white",
                  color: "#e43d3d",
                  maxWidth: "500px",
                  fontWeight: "bolder",
                  fontSize: "14px",
                  padding: "10px 20px",
                },
                iconTheme: {
                  primary: "#e43d3d",
                  secondary: "#FFFAEE",
                },
              }
            );
            setLoading(false);
          });
      } else if (broker === "Upstox") {
        let data = JSON.stringify({
          apiKey: apiKey,
          jwtToken: jwtToken,
          secretKey: secretKey,
          trades: stockDetails,
        });
        let orderConfig = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}upstox/process-trades`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(orderConfig)
          .then((response) => {
            // console.log("res=======", response);
            setLoading(false);
            setOpenSucessModal(true);
            setOrderPlacementResponse(response.data);
            setOpenSubscribeModel(false);
            subscribeModelPortfolio();
          })
          .catch((error) => {
            console.log("err", error);
            toast.error(
              "There was an issue in placing the trade, please try again after sometime or contact your advisor",
              {
                duration: 6000,
                style: {
                  background: "white",
                  color: "#e43d3d",
                  maxWidth: "500px",
                  fontWeight: "bolder",
                  fontSize: "14px",
                  padding: "10px 20px",
                },
                iconTheme: {
                  primary: "#e43d3d",
                  secondary: "#FFFAEE",
                },
              }
            );
            setLoading(false);
          });
      } else {
        let data = JSON.stringify({
          apiKey: apiKey,
          jwtToken: jwtToken,
          trades: stockDetails,
        });
        let orderConfig = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}/process-trades`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(orderConfig)
          .then((response) => {
            console.log("angel one order place res=======", response);
            setLoading(false);
            setOpenSucessModal(true);
            setOrderPlacementResponse(response.data);
            setOpenSubscribeModel(false);
            subscribeModelPortfolio();
          })
          .catch((error) => {
            console.log("err", error);
            toast.error(
              "There was an issue in placing the trade, please try again after sometime or contact your advisor",
              {
                duration: 6000,
                style: {
                  background: "white",
                  color: "#e43d3d",
                  maxWidth: "500px",
                  fontWeight: "bolder",
                  fontSize: "14px",
                  padding: "10px 20px",
                },
                iconTheme: {
                  primary: "#e43d3d",
                  secondary: "#FFFAEE",
                },
              }
            );
            setLoading(false);
          });
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2 ">
      <div className="relative">
        <div
          className="absolute right-2 top-6  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={() => setOpenSubscribeModel(false)}
        >
          <XIcon className="w-6 h-6 mr-2  " />
        </div>

        <div className="flex flex-col w-full h-[600px] lg:w-[820px] lg:h-full   bg-white  border-[#000000]/20 rounded-md pt-6 overflow-hidden">
          <div className="capitalize flex  w-full pb-4 text-[18px] lg:text-[22px]  text-[#000000] font-sans font-bold px-8 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
            Subscribing to {fileName?.replaceAll(/_/g, " ")}
          </div>
          {openStrategy === true ? (
            <div className="flex flex-col space-y-3 h-[480px]  overflow-y-scroll py-4 ">
              {confirmOrder === true ? (
                <div className="px-2 lg:px-8">
                  <div className="text-lg font-semibold font-poppins">
                    Investment Amount : ₹{" "}
                    {parseFloat(latestRebalance?.totalInvestmentvalue).toFixed(
                      2
                    )}
                  </div>
                  <div className="mt-2 test-sm text-[#000000]/40">
                    Adjusted to maintain stocks/ETFs in the suggested proportion
                  </div>
                </div>
              ) : (
                <div className="px-2 lg:px-8">
                  <div className="text-lg font-semibold font-poppins">
                    Confirm Amount
                  </div>
                  <div className="mt-2 test-sm text-[#000000]/40">
                    Min. Investment Amount
                  </div>
                  <div className="w-44 px-4 mt-2 py-3 rounded-md border-2 border-[#000000]/20">
                    ₹{" "}
                    {parseFloat(latestRebalance?.totalInvestmentvalue).toFixed(
                      2
                    )}
                  </div>
                </div>
              )}

              <div>
                <div className="text-lg font-semibold font-poppins px-2 lg:px-8">
                  Review Orders
                </div>
                <table className={`mt-4 w-full px-2 lg:px-8`}>
                  <thead>
                    <tr className="border-b-[1px] border-t-[1px]  border-[#000000]/10">
                      <th className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                        Constituents
                      </th>

                      <th className="text-[12px] min-w-[120px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Current Price (₹)
                      </th>

                      <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Weights(%)
                      </th>

                      <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Order Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {latestRebalance?.adviceEntries.map((ele, i) => {
                      return (
                        <tr
                          key={i}
                          className={`border-b-[1px]   border-[#000000]/10 last-of-type:border-none`}
                        >
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 ">
                            {ele.symbol}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-normal text-center py-3 px-5 ">
                            ₹ {getLTPForSymbol(ele.symbol)}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5 ">
                            {ele.value * 100}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 ">
                            Buy
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex flex-col space-y-3 h-[480px]  overflow-y-scroll px-2 lg:px-8 py-4 ">
              <div className="px-6 flex flex-row justify-between py-3 border-2 border-[#000000]/20 rounded-md">
                <div className="flex items-center">
                  <img
                    src={Checked}
                    alt="Checked Icon"
                    className="w-4 h-4 md:w-6 h-6 mr-2"
                  />
                  <span> Email</span>
                </div>
                <span className="font-semibold"> {userEmail}</span>
              </div>

              <div className="px-6 py-3 border-2 border-[#000000]/20 rounded-md">
                <div className="text-lg font-semibold">Select a plan</div>
                <div className="text-sm text-[#000000]/70">
                  Subscription will renew automatically
                </div>
                <div className="flex flex-row mt-8">
                  <div
                    className={
                      selectedOffer === "3 Months"
                        ? style.offerSelected
                        : style.offerNotSelected
                    }
                    onClick={() => setSelectedOffer("3 Months")}
                  >
                    <div>3 months</div>
                    <div>₹ 1300/m</div>
                  </div>
                  <div
                    className={
                      selectedOffer === "6 Months"
                        ? `ml-3 ${style.offerSelected}`
                        : `ml-3 ${style.offerNotSelected}`
                    }
                    onClick={() => setSelectedOffer("6 Months")}
                  >
                    <div>6 months</div>
                    <div>₹ 1083/m</div>
                  </div>
                </div>
              </div>
              {openCouponModal === true ? (
                <div className=" border-2 border-[#000000]/20 rounded-md">
                  <div className="px-6 py-4 ">
                    <div>Enter Coupon Code</div>
                    <input
                      type="text"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      className="px-4 w-full mt-4 py-3 text-balck bg-white border-2 border-[#000000]/20 rounded-md"
                      placeholder="Enter coupon code here"
                    />
                  </div>
                </div>
              ) : (
                <div className=" border-2 border-[#000000]/20 rounded-md">
                  <div className="px-6 py-2 flex flex-row justify-between border-dashed border-b-2 border-[#000000]/20">
                    <div className="flex flex-row items-center">
                      <TiDocumentText />
                      <span className="ml-2">Amount to pay</span>
                    </div>
                    <div>
                      ₹ {selectedOffer === "3 Months" ? "3900" : "6498"}
                    </div>
                  </div>

                  <div
                    className="px-6 py-2 flex flex-row justify-between cursor-pointer"
                    onClick={() => setOpenCouponModal(true)}
                  >
                    <div className="flex flex-row items-center">
                      <BiSolidOffer />
                      <span className="ml-2">Apply Offer</span>
                    </div>
                    <IoIosArrowForward />
                  </div>
                </div>
              )}

              <div className="mt-8">
                {" "}
                <button
                  disabled={!couponCode}
                  onClick={matchCode}
                  className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed flex flex-row items-center justify-center py-2 w-full mb-3 rounded-lg bg-black/100 text-white font-medium hover:bg-black/90 ease-linear duration-150"
                >
                  <div className="ml-4 text-white font-medium text-lg font-poppins ">
                    <div className="flex flex-row items-center justify-center">
                      Continue
                    </div>
                  </div>
                </button>
              </div>
            </div>
          )}
          {openStrategy === true ? (
            <div className="flex flex-col space-y-4 w-full  shadow-[0px_-4px_4px_0px_#0000000f] py-4 px-2 lg:px-8 ">
              {confirmOrder === true ? (
                <div className="flex flex-row justify-between">
                  <div>
                    <div>Total Investment</div>
                    <div>
                      ₹{" "}
                      {parseFloat(
                        latestRebalance?.totalInvestmentvalue
                      ).toFixed(2)}
                    </div>
                  </div>
                  <div className="flex justify-end space-x-5 ">
                    <button
                      className=" bg-[#ffffff] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#000000] font-poppins font-medium disabled:cursor-not-allowed border-4 border-[#000000]/20"
                      onClick={() => setConfirmOrder(false)}
                    >
                      Back
                    </button>
                    <button
                      className=" disabled:bg-[#000000]/50 bg-[#000000] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#ffffff] font-poppins font-medium disabled:cursor-not-allowed"
                      onClick={placeOrder}
                    >
                      {loading === true ? (
                        <LoadingSpinner />
                      ) : (
                        <span className="text-[18px] font-medium text-[#ffffff] font-poopins ">
                          Place Order
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex justify-end space-x-5 ">
                  <button
                    className=" bg-[#ffffff] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#000000] font-poppins font-medium disabled:cursor-not-allowed border-4 border-[#000000]/20"
                    onClick={() => setOpenStrategy(false)}
                  >
                    Back
                  </button>
                  <button
                    className=" disabled:bg-[#000000]/50 bg-[#000000] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#ffffff] font-poppins font-medium disabled:cursor-not-allowed"
                    onClick={() => setConfirmOrder(true)}
                  >
                    Confirm Details
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserStrategySubscribeModal;
