import React from "react";
import { X, Trash2, XIcon } from "lucide-react";

import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";

const ZerodhaReviewModal = ({
  calculateTotalAmount,
  getLTPForSymbol,
  stockDetails,
  setStockDetails,
  setOpenZerodhaModel,
  loading,
  handleZerodhaRedirect,
  openZerodhaModel,
  getCartAllStocks,
}) => {
  const handleDecreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: Math.max(stock.quantity - 1, 0) }
        : stock
    );
    setStockDetails(newData);
  };

  const handleIncreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: stock.quantity + 1 }
        : stock
    );
    setStockDetails(newData);
  };

  const handleQuantityInputChange = (symbol, value, tradeId) => {
    const newQuantity = parseInt(value) || 0;
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: newQuantity }
        : stock
    );
    setStockDetails(newData);
  };

  const handleRemoveStock = (symbol, tradeId) => {
    setStockDetails(
      stockDetails.filter(
        (stock) => stock.tradingSymbol !== symbol || stock.tradeId !== tradeId
      )
    );
  };

  const isDesktop = useMediaQuery("(min-width: 1199px)");

  if (isDesktop) {
    return (
      <Dialog open={openZerodhaModel} onOpenChange={setOpenZerodhaModel}>
        <DialogContent className="lg:max-w-[65vw] w-full p-0 animate-modal">
          {/* <div
            onClick={() => {
              setOpenZerodhaModel(false);
              getCartAllStocks();
            }}
            className="bg-white absolute top-4 right-3 flex items-center justify-center "
          >
            <XIcon className="h-5 w-5 text-gray-900" />
          </div> */}
          <div className="flex flex-col  w-full  rounded-[8px] pt-4  h-[630px] lg:h-auto bg-[#ffffff] ">
            <div className="px-6 flex flex-row justify-between items-center">
              <div className="text-xl text-black font-semibold leading-[40px] font-sans">
                Review Zerodha Trade Details
              </div>
            </div>
            <div className="my-3 py-2 px-6 bg-[#000000]/10 text-[16px] text-[#000000]/70 font-normal font-poppins">
              Complete Zerodha transaction
            </div>

            <div className="px-6 flex flex-row items-center">
              <div className="text-[15px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[40px] font-sans">
                Confirm Total Amount :
              </div>
              <div className="px-4 py-1 ml-6 lg:py-2 lg:px-8 text-lg text-[#000000]/80 font-bold border border-[#000000]/20 rounded-md">
                ₹{calculateTotalAmount()}
              </div>
            </div>

            {/* Stock details table */}
            <div className="w-full h-full lg:h-[400px] mt-8 overflow-auto custom-scroll">
              <table className="w-full">
                {/* Table header */}
                <thead>
                  <tr className="border-b-[1px] border-t-[1px] border-[#000000]/10">
                    <th className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                      Stocks
                    </th>
                    <th className="text-[12px] min-w-[120px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                      Current Price (₹)
                    </th>
                    <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                      Order (₹)
                    </th>
                    <th className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                      Quantity
                    </th>
                    <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                      Order Type
                    </th>
                    <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                      Delete
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody>
                  {stockDetails.map((stock, i) => (
                    <tr
                      key={i}
                      className="border-b-[1px] border-[#000000]/10 last-of-type:border-none"
                    >
                      <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8">
                        {stock.tradingSymbol}
                      </td>
                      <td className="text-[15px] text-[#000000]/80 font-poppins font-normal text-center py-3 px-5">
                        {getLTPForSymbol(stock.tradingSymbol)}
                      </td>
                      <td
                        className={
                          stock?.transactionType?.toLowerCase() === "buy"
                            ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5"
                            : stock?.transactionType?.toLowerCase() === "sell"
                            ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5"
                            : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5"
                        }
                      >
                        {stock.transactionType}
                      </td>
                      <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5">
                        <div className="flex flex-row items-center justify-between text-[12px] text-[#000000]/80 font-poppins">
                          <button
                            onClick={() =>
                              handleDecreaseStockQty(
                                stock.tradingSymbol,
                                stock.tradeId
                              )
                            }
                            disabled={stock.quantity <= 1}
                            className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 rounded-[4px]"
                          >
                            -
                          </button>
                          <input
                            type="text"
                            value={stock.quantity}
                            onChange={(e) =>
                              handleQuantityInputChange(
                                stock.tradingSymbol,
                                e.target.value,
                                stock.tradeId
                              )
                            }
                            className="flex flex-1 items-center justify-center w-[45px] h-[28px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
                          />
                          <button
                            onClick={() =>
                              handleIncreaseStockQty(
                                stock.tradingSymbol,
                                stock.tradeId
                              )
                            }
                            className="cursor-pointer hover:bg-black hover:text-white px-1 rounded-[4px]"
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5">
                        {stock.orderType}
                        {stock.orderType === "LIMIT" ||
                        stock.orderType === "STOP" ? (
                          <span>({stock.price})</span>
                        ) : null}
                      </td>
                      <td className="text-[15px] text-[#000000]/80 font-poppins font-medium flex flex-row justify-center text-center py-3 px-3 lg:py-4 lg:px-5">
                        <Trash2
                          size={18}
                          className="cursor-pointer hover:text-[#ff0000]"
                          onClick={() =>
                            handleRemoveStock(
                              stock.tradingSymbol,
                              stock.tradeId
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <DialogFooter className="flex p-4">
            <div
              onClick={() => {
                setOpenZerodhaModel(false);
                getCartAllStocks();
              }}
              className="w-[180px] bg-[#ffffff] flex items-center justify-center  text-black text-[18px] font-medium font-poppins border-2 border-[#000000]/20 rounded-md py-3 cursor-pointer  transition-all duration-150 ease-linear"
            >
              Cancel
            </div>

            <button
              className="w-[180px]  px-8 py-3 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
              onClick={handleZerodhaRedirect}
            >
              {loading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[18px] font-medium text-[#ffffff] font-poopins ">
                  Place Order
                </span>
              )}
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={openZerodhaModel} onOpenChange={setOpenZerodhaModel}>
      <DrawerContent className="focus:outline-none max-h-[550px] md:max-h-[690px]">
        <div
          onClick={() => {
            setOpenZerodhaModel(false);
            getCartAllStocks();
          }}
          className="absolute top-4 right-3 flex items-center justify-center "
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>

        <div className="pt-4 h-full overflow-y-auto">
          <div className="px-6 flex flex-row justify-between items-center">
            <div className="text-xl text-black font-semibold leading-[40px] font-sans">
              Review Zerodha Trade Details
            </div>
          </div>
          <div className="my-3 py-2 px-6 bg-[#000000]/10 text-[16px] text-[#000000]/70 font-normal font-poppins">
            Complete Zerodha transaction
          </div>

          <div className="px-6 flex flex-row items-center">
            <div className="text-[15px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[40px] font-sans">
              Confirm Total Amount :
            </div>
            <div className="px-4 py-1 ml-6 lg:py-2 lg:px-8 text-lg text-[#000000]/80 font-bold border border-[#000000]/20 rounded-md">
              ₹{calculateTotalAmount()}
            </div>
          </div>

          {/* Stock details table */}
          <div className="w-full h-[400px] mt-8 overflow-auto custom-scroll">
            <table className="w-full">
              {/* Table header */}
              <thead>
                <tr className="border-b-[1px] border-t-[1px] border-[#000000]/10">
                  <th className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                    Stocks
                  </th>
                  <th className="text-[12px] min-w-[120px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Current Price (₹)
                  </th>
                  <th className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Order (₹)
                  </th>
                  <th className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Quantity
                  </th>
                  <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Order Type
                  </th>
                  <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Delete
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody>
                {stockDetails.map((stock, i) => (
                  <tr
                    key={i}
                    className="border-b-[1px] border-[#000000]/10 last-of-type:border-none"
                  >
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8">
                      {stock.tradingSymbol}
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-normal text-center py-3 px-5">
                      {getLTPForSymbol(stock.tradingSymbol)}
                    </td>
                    <td
                      className={
                        stock?.transactionType?.toLowerCase() === "buy"
                          ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5"
                          : stock?.transactionType?.toLowerCase() === "sell"
                          ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5"
                          : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5"
                      }
                    >
                      {stock.transactionType}
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5">
                      <div className="flex flex-row items-center justify-between text-[12px] text-[#000000]/80 font-poppins">
                        <button
                          onClick={() =>
                            handleDecreaseStockQty(
                              stock.tradingSymbol,
                              stock.tradeId
                            )
                          }
                          disabled={stock.quantity <= 1}
                          className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 rounded-[4px]"
                        >
                          -
                        </button>
                        <input
                          type="text"
                          value={stock.quantity}
                          onChange={(e) =>
                            handleQuantityInputChange(
                              stock.tradingSymbol,
                              e.target.value,
                              stock.tradeId
                            )
                          }
                          className="flex flex-1 items-center justify-center w-[45px] h-[28px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
                        />
                        <button
                          onClick={() =>
                            handleIncreaseStockQty(
                              stock.tradingSymbol,
                              stock.tradeId
                            )
                          }
                          className="cursor-pointer hover:bg-black hover:text-white px-1 rounded-[4px]"
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5">
                      {stock.orderType}
                      {stock.orderType === "LIMIT" ||
                      stock.orderType === "STOP" ? (
                        <span>({stock.price})</span>
                      ) : null}
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium flex flex-row justify-center text-center py-3 px-3 lg:py-4 lg:px-5">
                      <Trash2
                        size={18}
                        className="cursor-pointer hover:text-[#ff0000]"
                        onClick={() =>
                          handleRemoveStock(stock.tradingSymbol, stock.tradeId)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <DrawerFooter className="pt-2 flex flex-col  w-full">
          <button
            className="w-full  px-8 py-3 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
            onClick={handleZerodhaRedirect}
          >
            {loading === true ? (
              <LoadingSpinner />
            ) : (
              <span className="text-[18px] font-medium text-[#ffffff] font-poopins ">
                Place Order
              </span>
            )}
          </button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ZerodhaReviewModal;
