import React, { useState, useCallback, useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import moment from "moment";
import server from "../../utils/serverConfig";
import { XIcon, Plus, Trash2Icon } from "lucide-react";
import { debounce } from "lodash";
import { io } from "socket.io-client";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import LoadingSpinner from "../../components/LoadingSpinner";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full py-1.5 px-2 bg-white text-gray-900 placeholder:text-gray-400 placeholder:font-normal   font-medium rounded-md ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9]",

  selectDiv:
    "flex flex-row justify-between items-center mx-2 px-2 py-2 rounded-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-black transition ease-in duration-200 cursor-pointer",
  inputStartDiv: " relative w-full   ",
};
const NewSendAdviceModel = ({
  fileName,
  closeSendAdviceModal,
  data,
  adminEmail,
  advisorName,
  email_password,
  setSendAdviceModal,
  setSelectedRows,
}) => {
  const [loading, setLoading] = useState(false);
  const [adviceEntries, setAdviceEntries] = useState([
    {
      id: Date.now(),
      symbol: "",
      exchange: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: 0,
      segment: "EQUITY",
      price: 0,
      type: "",
      adviceLower: 0,
      adviceHigher: 0,
      inputValue: "",
      symbols: [],
      price_when_send_advice: 0,
      rationale:
        "This recommendation is based on a comprehensive analysis of the company's growth potential and value metrics. This recommendation also accounts for potential future risks, ensuring a balanced approach to maximizing returns while mitigating uncertainties. Please contact your advisor for any queries.",
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddAdviceEntry = () => {
    const newEntry = {
      id: Date.now(),
      symbol: "",
      exchange: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: 0,
      segment: "EQUITY",
      price: 0,
      type: "",
      adviceLower: 0,
      adviceHigher: 0,
      inputValue: "",
      symbols: [],
      price_when_send_advice: 0,
      rationale:
        "This recommendation is based on a comprehensive analysis of the company's growth potential and value metrics. This recommendation also accounts for potential future risks, ensuring a balanced approach to maximizing returns while mitigating uncertainties. Please contact your advisor for any queries.",
    };

    setAdviceEntries([newEntry, ...adviceEntries]);
  };

  const handleRemoveAdviceEntry = (index) => {
    const updatedEntries = adviceEntries.filter((_, i) => i !== index);
    setAdviceEntries(updatedEntries);
  };

  const numericFields = ["price", "anotherNumericField"]; // Add all numeric field names here

  const handleAdviceChange = (index, field, value) => {
    let formattedValue = value;

    // Format value if the field is numeric
    if (numericFields?.includes(field)) {
      // Allow only valid numbers with up to two decimal places
      if (value) {
        const [integerPart, decimalPart] = value.split(".");
        if (decimalPart && decimalPart.length > 2) {
          formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
        } else {
          formattedValue = value;
        }
      }
    }

    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index ? { ...entry, [field]: formattedValue } : entry
    );
    setAdviceEntries(updatedEntries);
  };

  const handleSymbolSelect = (index, symbol, exchange) => {
    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index
        ? {
            ...entry,
            symbol: symbol,
            symbols: [],
            inputValue: symbol,
            exchange: exchange,
          }
        : entry
    );
    setAdviceEntries(updatedEntries);
  };

  const fetchSymbols = async (index, inputValue) => {
    const data = JSON.stringify({
      symbol: inputValue,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${server.ccxtServer.baseUrl}get-symbol-name-exchange`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, symbols: response.data.match } : entry
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetchSymbols = useCallback(
    debounce((index, value) => {
      fetchSymbols(index, value);
    }, 300),
    []
  );

  const handleInputChange = (index, value) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, inputValue: value, symbol: value } : entry
      )
    );

    if (value.length >= 3) {
      debouncedFetchSymbols(index, value);
      setIsOpen(true);
    }
  };

  const [ltp, setLtp] = useState([]);

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize WebSocket connection
    const newSocket = io("wss://ccxt.alphaquark-case.com", {
      transports: ["websocket"],
      query: { EIO: "4" },
    });

    newSocket.on("connect", () => {
      // console.log("Connected to Socket.IO server");
    });

    newSocket.on("market_data", (data) => {
      setLtp((prev) => {
        const index = prev.findIndex(
          (item) => item.tradingSymbol === data.stockSymbol
        );
        if (index !== -1) {
          const newLtp = [...prev];
          newLtp[index] = {
            ...newLtp[index],
            lastPrice: data.last_traded_price,
          };
          return newLtp;
        } else {
          const updatedLtp = [
            ...prev,
            {
              tradingSymbol: data.stockSymbol,
              lastPrice: data.last_traded_price,
            },
          ];
          return updatedLtp;
        }
      });
    });

    newSocket.on("disconnect", () => {
      // console.log("Disconnected from Socket.IO server");
    });

    const pingInterval = setInterval(() => {
      if (newSocket.connected) {
        newSocket.emit("ping");
      }
    }, 20000);

    setSocket(newSocket);

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  const getCurrentPrice = () => {
    if (!adviceEntries || adviceEntries.length === 0) {
      // console.log("No trades available to fetch prices for");
      return;
    }

    const uniqueSymbols = new Set();
    const symbolsToSubscribe = adviceEntries
      .map((trade) => ({
        exchange: trade.exchange,
        tradingSymbol: trade.symbol,
      }))
      .filter((order) => {
        if (uniqueSymbols.has(order.tradingSymbol)) {
          return false;
        } else {
          uniqueSymbols.add(order.tradingSymbol);
          return true;
        }
      });

    symbolsToSubscribe.forEach((symbol) => {
      const data = {
        symbol: symbol.tradingSymbol,
        exchange: symbol.exchange,
      };

      axios
        .post("https://ccxt.alphaquark-case.com/websocket/subscribe", data)
        .then((response) => {
          // console.log(`Subscribed to ${symbol.tradingSymbol}:`, response.data);
        })
        .catch((error) => {
          // console.error(`Error subscribing to ${symbol.tradingSymbol}:`, error);
        });
    });
  };

  useEffect(() => {
    if (adviceEntries && adviceEntries.length > 0) {
      getCurrentPrice();
    }
  }, [adviceEntries]);

  const getLTPForSymbol = (symbol) => {
    // Remove suffixes from the input symbol
    const ltpOne = ltp.find((item) => {
      return item.tradingSymbol === symbol;
    })?.lastPrice;
    return ltpOne ? ltpOne.toFixed(2) : "-";
  };

  const handleRationaleChange = (index, rationaleMsg) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, rationale: rationaleMsg } : entry
      )
    );
  };

  const isValid = adviceEntries.every(
    (entry) =>
      entry.symbol &&
      entry.exchange &&
      entry.orderType &&
      entry.productType &&
      entry.quantity > 0 &&
      entry.segment &&
      entry.type &&
      entry.rationale
  );

  const todaydate = new Date();

  const handleSendAdvice = () => {
    setLoading(true);

    const adviceData = data.flatMap((user) =>
      adviceEntries.map((entry) => ({
        email: user.email,
        userName: user?.clientName,
        phoneNumber: user?.phone,
        telegramId: user?.telegram,
        trade_given_by: adminEmail,
        email_password: email_password,
        trade_given_by_fileName: fileName,
        advisor_name: advisorName,
        Symbol: entry.symbol,
        Exchange: entry.exchange,
        Type: entry.type,
        OrderType: entry.orderType,
        ProductType: entry.productType,
        Segment: entry.segment,
        Price: entry.orderType === "MARKET" ? 0 : entry.price,
        date: moment(todaydate).format(),
        Quantity: entry.quantity,
        Advised_Range_Lower: entry.adviceLower,
        Advised_Range_Higher: entry.adviceHigher,
        rationale: entry?.rationale,
        price_when_send_advice: getLTPForSymbol(entry?.symbol),
      }))
    );
    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/send-reco`,
      headers: {
        "Content-Type": "application/json",
      },
      data: adviceData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        toast.success("Advice has been sent successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#16a085",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "16px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setTimeout(() => {
          setAdviceEntries([
            {
              symbol: "",
              exchange: "NSE",
              orderType: "MARKET",
              productType: "DELIVERY",
              quantity: 0,
              segment: "EQUITY",
              price: 0,
              type: "",
              adviceLower: 0,
              adviceHigher: 0,
              inputValue: "",
              symbols: [],
            },
          ]);
          setSendAdviceModal(false);
          setSelectedRows([]);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in sending Advice !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const formatToTwoDecimals = (value) => {
    // Remove any non-digit and non-decimal point characters
    let formatted = value.replace(/[^\d.]/g, "");

    // Ensure only one decimal point
    const parts = formatted.split(".");
    if (parts.length > 2) {
      formatted = parts[0] + "." + parts.slice(1).join("");
    }

    // Limit to two decimal places
    if (parts.length > 1) {
      formatted = parts[0] + "." + parts[1].slice(0, 2);
    }

    return formatted;
  };

  return (
    <div className="w-full fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 ">
      <div className="relative mt-6 md:pb-4  max-w-[100vw] w-full bg-[#ffffff] animate-modal">
        <div
          className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={closeSendAdviceModal}
        >
          <XIcon className="w-6 h-6 mr-2" />
        </div>
        <div className="w-full px-4 pt-10 pb-4 md:px-8 md:py-8 bg-white  border-[#000000]/20 ">
          <div className=" text-[18px] lg:text-[26px] font-semibold font-sans text-center capitalize ">
            Send Advice to {fileName?.replaceAll(/_/g, " ")}
          </div>

          <div className="mt-6 text-[18px] font-normal font-poppins  ">
            <div className="mt-6 h-full pb-4">
              <div className="border-t-[1px] border-[#000000]/10 mt-6 md:pb-4  max-w-[100vw] h-[350px] md:h-[440px] overflow-auto custom-scroll bg-[#ffffff]">
                <table className="w-full">
                  <thead className="bg-[#f9f9f9] text-sm text-left sticky top-0 z-50">
                    <tr className="border-b-[1px]   border-[#000000]/10">
                      <th className=" text-[12px] md:text-[14px] border-l-[1px] border-r-[1px] border-[#000000]/10 text-[#000000]/60 font-poppins font-medium py-1 px-2">
                        <div className="flex items-center justify-center bg-black py-1 rounded-md">
                          <Plus
                            className="cursor-pointer text-white  w-[25px] h-[20px] "
                            strokeWidth={3}
                            onClick={handleAddAdviceEntry}
                          />
                        </div>
                      </th>
                      <th className="text-[12px] md:text-[14px]  text-center border-r-[1px]  border-[#000000]/10 text-[#000000]/60 font-poppins font-medium py-3 px-1">
                        Symbol
                        <span className=" text-red-500 text-[12px] md:text-[14px]">
                          *
                        </span>
                      </th>

                      <th className="text-[12px] md:text-[14px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60 text-center font-poppins font-medium py-3 px-1">
                        Order Type
                        <span className=" text-red-500 text-[12px] md:text-[14px]">
                          *
                        </span>
                      </th>

                      <th className="text-[12px] md:text-[14px] min-w-[130px] border-r-[1px]  border-[#000000]/10 text-center text-[#000000]/60 font-poppins font-medium py-3 px-1">
                        Product Type
                        <span className=" text-red-500 text-[12px] md:text-[14px]">
                          *
                        </span>
                      </th>
                      <th className="text-[12px] md:text-[14px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60 text-center font-poppins font-medium py-3 px-1">
                        Segment
                        <span className=" text-red-500 text-[12px] md:text-[14px]">
                          *
                        </span>
                      </th>
                      <th className="text-[12px] md:text-[14px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60 text-center font-poppins font-medium py-3 px-1">
                        Type
                        <span className=" text-red-500 text-[12px] md:text-[14px]">
                          *
                        </span>
                      </th>
                      <th className="text-[12px] md:text-[14px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60 text-center font-poppins font-medium py-3 px-1">
                        Quantity
                        <span className=" text-red-500 text-[12px] md:text-[14px]">
                          *
                        </span>
                      </th>
                      <th className=" min-w-[100px] text-[12px] md:text-[14px] border-r-[1px]  border-[#000000]/10 text-center text-[#000000]/60 font-poppins font-medium py-3 px-1">
                        Ltp
                        <span className=" text-red-500 text-[12px] md:text-[14px]">
                          *
                        </span>
                      </th>
                      <th className=" min-w-[100px] text-[12px] md:text-[14px] border-r-[1px]  border-[#000000]/10 text-center text-[#000000]/60 font-poppins font-medium py-3 px-1">
                        Price
                        <span className=" text-red-500 text-[12px] md:text-[14px]">
                          *
                        </span>
                      </th>
                      <th className="min-w-[100px] text-[12px] md:text-[14px] border-r-[1px]  border-[#000000]/10 text-center text-[#000000]/60 font-poppins font-medium py-3 px-1">
                        Total Value
                      </th>
                      <th className=" text-[12px] md:text-[14px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60 text-center font-poppins font-medium py-3 px-1 text-nowrap">
                        <div className="flex flex-col">Advised Range</div>
                      </th>
                      <th className="min-w-[100px] text-[12px] md:text-[14px] border-r-[1px]  border-[#000000]/10 text-[#000000]/60 text-center font-poppins font-medium py-3 px-1">
                        Rationale
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {adviceEntries?.map((entry, index) => {
                      return (
                        <tr
                          key={entry.id}
                          className="relative border-b-[1px]   border-[#000000]/10"
                        >
                          {adviceEntries.length > 1 ? (
                            <td
                              onClick={() => handleRemoveAdviceEntry(index)}
                              className="cursor-pointer  text-[12px] md:text-[14px] border-l-[1px] border-r-[1px] border-[#000000]/10 text-[#000000]/60 font-poppins font-medium px-1 py-2 "
                            >
                              <div className="flex items-center justify-center">
                                <Trash2Icon className="w-4 h-4 hover:text-[#ff0000]" />
                              </div>
                            </td>
                          ) : (
                            <td className="border-l-[1px] border-r-[1px] cursor-pointer "></td>
                          )}
                          <td className="text-[12px] md:text-[14px] border-r-[1px]  text-left  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                            <div className="">
                              <input
                                type="text"
                                placeholder="Symbol"
                                className={`${style.inputBox} min-w-[100px]`}
                                value={entry.inputValue}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                                autoComplete="off"
                              />

                              {entry.symbols.length > 0 && isOpen && (
                                <div
                                  ref={dropdownRef}
                                  className="w-[300px] absolute left-[38px] right-0 top-[70px] mt-1 bg-white custom-scroll flex flex-col rounded-lg pt-2 pb-2 z-50 box-border border-2 border-solid border-[ rgba(185, 192, 187, 0.7)] max-h-48 overflow-y-auto"
                                >
                                  {entry.symbols.map((ele, idx) => {
                                    return (
                                      <div
                                        key={idx}
                                        onClick={() => {
                                          handleSymbolSelect(
                                            index,
                                            ele.symbol,
                                            ele.segment
                                          );
                                        }}
                                        className={`pl-3 medium ${style.selectDiv}`}
                                      >
                                        <span> {ele.symbol}</span>
                                        <span> {ele.segment}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}

                              {entry.symbols.length === 0 &&
                                entry.inputValue.length > 4 && (
                                  <div
                                    ref={dropdownRef}
                                    className="w-[300px] absolute left-[38px] right-0 top-[70px] mt-1 bg-white custom-scroll flex flex-col rounded-lg pt-2 pb-2 z-50 box-border border-2 border-solid border-[ rgba(185, 192, 187, 0.7)] max-h-48 overflow-y-auto"
                                  >
                                    <div
                                      className={`pl-3 medium ${style.selectDiv}`}
                                    >
                                      <span>
                                        Symbol Incorrect or Unavailable
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </td>

                          <td className=" text-[12px] md:text-[14px] border-r-[1px]  text-left  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                            <Select
                              defaultValue="MARKET"
                              onValueChange={(value) =>
                                handleAdviceChange(index, "orderType", value)
                              }
                            >
                              <SelectTrigger className="w-[120px] font-poppins font-medium">
                                <SelectValue
                                  placeholder={"Select Order Type"}
                                />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  {["MARKET", "LIMIT", "STOP"].map(
                                    (option, index) => (
                                      <SelectItem
                                        className="font-poppins font-medium"
                                        key={index}
                                        value={option}
                                      >
                                        {option}
                                      </SelectItem>
                                    )
                                  )}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </td>
                          <td className="text-[12px] md:text-[14px] border-r-[1px]  text-left  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                            <Select
                              defaultValue="DELIVERY"
                              onValueChange={(value) =>
                                handleAdviceChange(index, "productType", value)
                              }
                            >
                              <SelectTrigger className="w-[120px] font-poppins font-medium">
                                <SelectValue
                                  placeholder={"Select Product Type"}
                                />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  {["DELIVERY", "INTRADAY"].map(
                                    (option, index) => (
                                      <SelectItem
                                        className="font-poppins font-medium"
                                        key={index}
                                        value={option}
                                      >
                                        {option}
                                      </SelectItem>
                                    )
                                  )}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </td>
                          <td className="text-[12px] md:text-[14px] border-r-[1px]  text-left  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                            <Select
                              defaultValue="EQUITY"
                              onValueChange={(value) =>
                                handleAdviceChange(index, "segment", value)
                              }
                            >
                              <SelectTrigger className="w-[120px] font-poppins font-medium">
                                <SelectValue placeholder={"Select Segment"} />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  {["EQUITY"].map((option, index) => (
                                    <SelectItem
                                      className="font-poppins font-medium"
                                      key={index}
                                      value={option}
                                    >
                                      {option}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </td>
                          <td className="text-[12px] md:text-[14px] border-r-[1px]  text-left  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                            <Select
                              onValueChange={(value) =>
                                handleAdviceChange(index, "type", value)
                              }
                            >
                              <SelectTrigger className="w-[120px] font-poppins font-medium px-2">
                                <SelectValue placeholder={"Select Type"} />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  {["BUY", "SELL"].map((option, index) => (
                                    <SelectItem
                                      className="font-poppins font-medium"
                                      key={index}
                                      value={option}
                                    >
                                      {option}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </td>
                          <td className=" text-[12px] md:text-[14px] border-r-[1px]  text-left  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                            <div>
                              <input
                                type="number"
                                placeholder="Qty"
                                className={`${style.inputBox} py-1  max-w-[100px]`}
                                value={
                                  entry?.quantity > 0 ? entry?.quantity : ""
                                }
                                onChange={(e) =>
                                  handleAdviceChange(
                                    index,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td
                            className={`text-[12px] md:text-[14px] border-r-[1px]  ${
                              getLTPForSymbol(entry?.symbol) === "NaN" ||
                              getLTPForSymbol(entry?.symbol) === "-"
                                ? "text-center"
                                : "text-right"
                            }  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2`}
                          >
                            <span>
                              {entry?.symbol && getLTPForSymbol(entry?.symbol)}
                            </span>
                          </td>
                          <td
                            className={`text-[12px] md:text-[14px] border-r-[1px]  ${
                              getLTPForSymbol(entry?.symbol) === "NaN" ||
                              getLTPForSymbol(entry?.symbol) === "-"
                                ? "text-center"
                                : "text-right"
                            }  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2`}
                          >
                            {entry.orderType === "MARKET" ? (
                              <span>
                                {entry?.symbol &&
                                  getLTPForSymbol(entry?.symbol)}
                              </span>
                            ) : (
                              <input
                                type="number"
                                placeholder="Price"
                                className={`text-right ${style.inputBox} max-w-[80px]`}
                                value={entry?.price > 0 ? entry?.price : ""}
                                onChange={(e) =>
                                  handleAdviceChange(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                              />
                            )}
                          </td>
                          <td className="text-[12px] md:text-[14px] border-r-[1px]  text-right  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                            {entry.orderType === "MARKET" && entry.quantity > 0
                              ? (
                                  getLTPForSymbol(entry?.symbol) *
                                  entry?.quantity
                                ).toFixed(2)
                              : entry.orderType === "LIMIT" ||
                                (entry.orderType === "STOP" &&
                                  entry.quantity > 0)
                              ? entry?.quantity * entry?.price
                              : ""}
                          </td>
                          <td className=" text-[12px] md:text-[14px] border-r-[1px]  text-left  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                            <div className="flex flex-row items-center">
                              <input
                                type="number"
                                placeholder="Low"
                                className={`${style.inputBox} min-w-12`}
                                value={
                                  entry?.adviceLower > 0
                                    ? entry?.adviceLower
                                    : ""
                                }
                                onChange={(e) => {
                                  const formattedValue = formatToTwoDecimals(
                                    e.target.value
                                  );
                                  handleAdviceChange(
                                    index,
                                    "adviceLower",
                                    formattedValue
                                  );
                                }}
                              />

                              <span className="text-sm mx-2">-</span>
                              <input
                                type="number"
                                placeholder="High"
                                className={`${style.inputBox} min-w-12`}
                                value={
                                  entry?.adviceHigher > 0
                                    ? entry?.adviceHigher
                                    : ""
                                }
                                onChange={(e) => {
                                  const formattedValue = formatToTwoDecimals(
                                    e.target.value
                                  );
                                  handleAdviceChange(
                                    index,
                                    "adviceHigher",
                                    formattedValue
                                  );
                                }}
                              />
                            </div>
                          </td>
                          <td className=" text-[12px] md:text-[14px] border-r-[1px]  text-left  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                            <div>
                              <textarea
                                rows={3}
                                className={`${style.inputBox} py-1 min-w-[230px] custom-scroll`}
                                value={entry.rationale}
                                onChange={(e) =>
                                  handleRationaleChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-row justify-end items-center">
                <button
                  onClick={handleSendAdvice}
                  disabled={!isValid}
                  className="w-[210px] disabled:bg-[#000000]/20 mt-6 text-center bg-black disabled:cursor-not-allowed text-white text-[18px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer hover:scale-[1.02] transition-all duration-150 ease-linear"
                >
                  {loading === true ? <LoadingSpinner /> : " Send Advice"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSendAdviceModel;
