import React from "react";
import { XIcon, CircleCheckIcon, CircleIcon } from "lucide-react";

const rebalanceTimelineData = [
  {
    text: "Next Review",
    date: "Jul 1, 2024",
    complete: false,
  },
  {
    text: "+1 | -1 Constituents",
    date: "Apr 1, 2024",
    complete: false,
  },
  {
    text: "Weights updated of 4 constituents",
    date: "Jan 1, 2024",
    complete: true,
  },
  {
    text: "Weights updated of 4 constituents",
    date: "Oct 3, 2023",
    complete: true,
  },
  {
    text: "18+ Rebalances",
    date: "Jul 3, 2023",
    complete: true,
  },
  {
    text: "Strategy Went Live",
    date: "Jan1, 2022",
    complete: true,
  },
];

const RebalanceTimeLineModal = ({ closeRebalanceTimelineModal }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2 ">
      <div className="relative">
        <div
          className="absolute right-2 top-6  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={closeRebalanceTimelineModal}
        >
          <XIcon className="w-6 h-6 mr-2  " />
        </div>

        <div className="flex flex-col w-full lg:w-[620px] h-full  bg-white  border-[#000000]/20 rounded-md pt-6 overflow-hidden">
          <div className="flex  w-full pb-4 text-[18px] lg:text-[22px]  text-[#000000] font-sans font-bold px-8 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
            Rebalance Timeline
          </div>
          <div className="relative flex flex-col  space-y-[23px]  max-h-[440px]  overflow-y-auto px-[14px] py-10 lg:px-8 lg:py-12">
            {rebalanceTimelineData.map((item, index) => (
              <div className="z-10 group flex items-start justify-between  ">
                {/* <div className="flex flex-1 space-x-3 items-start justify-start ">
                  <div className="relative min-h-6 min-w-6 rounded-full">
                    
                    {item.complete === true ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className=" h-full w-full  rounded-full fill-green-600 text-white"
                      >
                        <circle cx="12" cy="12" r="12"></circle>
                        <path d="m9 12 2 2 4-4"></path>
                      </svg>
                    ) : (
                      <CircleIcon className="h-full w-full  rounded-full  text-[#000000]/20" />
                    )}

                    <div className="absolute -rotate-90 -left-[0px] -top-[12px] h-[1px] w-[25px] bg-[#000000]/10 "></div>
                    <div className="hidden absolute group-last-of-type:flex -rotate-90 -left-[0px] -bottom-[12px] h-[1px] w-[25px] bg-[#000000]/10 "></div>
                  </div>

                  <div className="text-[14px] font-poppins font-medium text-[#000000]/80">
                    {item.text}
                  </div>
                </div> */}

                <div key={index} className=" flex items-start space-x-3">
                  <div className="relative flex-shrink-0">
                    {item.complete ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6 rounded-full fill-green-600 text-white"
                      >
                        <circle cx="12" cy="12" r="12"></circle>
                        <path d="m9 12 2 2 4-4"></path>
                      </svg>
                    ) : (
                      <CircleIcon className="h-6 w-6 rounded-full fill-[#ffffff] text-gray-300" />
                    )}
                  </div>

                  <div className="flex-1">
                    <div className="text-sm font-poppins font-medium text-gray-800">
                      {item.text}
                    </div>
                  </div>
                </div>

                <div className="flex min-w-[110px]  justify-end lg:max-w-[180px] text-[14px] font-poppins font-medium text-[#000000]/60">
                  {item.date}
                </div>

              </div>
            ))}
            <div className="z-0 absolute left-[26px] lg:left-[43px] top-0 h-[calc(100%-44px)] w-px bg-[#000000]/20"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RebalanceTimeLineModal;
