import React, { useState } from "react";
import DashboardLayout from "./DashboardLayout";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import { Button } from "../components/ui/button";
import { ChevronsUpDown, Check } from "lucide-react";
import { cn } from "../utils/utils";

const frameworks = [
  {
    value: "next.js",
    label: "Next.js",
  },
  {
    value: "sveltekit",
    label: "SvelteKit",
  },
  {
    value: "nuxt.js",
    label: "Nuxt.js",
  },
  {
    value: "remix",
    label: "Remix",
  },
  {
    value: "astro",
    label: "Astro",
  },
];

export default function Settings() {
  const [selectedOption1, setSelectedOption1] = useState("Option C");
  const [selectedOption2, setSelectedOption2] = useState("Option X");

  const options1 = ["Option A", "Option B", "Option C"];
  const options2 = ["Option X", "Option Y", "Option Z"];

  const handleSelect1Change = (value) => {
    setSelectedOption1(value);
  };

  const handleSelect2Change = (value) => {
    setSelectedOption2(value);
  };

  const [data, setData] = useState(Array(50).fill("")); // Initial data for 50 rows
  const [openStates, setOpenStates] = useState(Array(50).fill(false)); // Track open state for each row
  const [values, setValues] = useState([]); // Track selected value for each row

  const handleInputChange = (index, val) => {
    console.log("index is", index);
    console.log("Val is", val);
  };

  const handleOpenChange = (index, isOpen) => {
    setOpenStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = isOpen;
      return newStates;
    });
  };

  const handleValueChange = (index, newValue) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = newValue;
      return newValues;
    });
  };

  console.log("value is ",values)

  return (
    <DashboardLayout>
      <div className="flex flex-col lg:min-h-screen w-full items-center justify-center text-black bg-red-500">
        This is Settings.
        <div className="mt-6  max-h-[480px] w-[800px] h-full pb-4">
        <div className="mt-6 pb-4 pr-2 max-w-[90vw] w-full max-h-[280px] h-full bg-[#ffffff] overflow-auto custom-scroll">
          <table className="min-w-full bg-white relative">
            <thead>
              <tr>
                <th className="px-4 py-2 border">Input</th>
                <th className="px-4 py-2 border">Symbol</th>
                <th className="px-4 py-2 border">Qty</th>
                <th className="px-4 py-2 border">Price</th>
                <th className="px-4 py-2 border">Total val</th>
              </tr>
            </thead>
            <tbody>
              {data.map((val, index) => (
                <tr key={index} className="relative">
                  <Popover
                    open={openStates[index]}
                    onOpenChange={(isOpen) => handleOpenChange(index, isOpen)}
                  >
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={openStates[index]}
                        className="w-[200px] justify-between"
                      >
                        {values[index]
                          ? frameworks.find(
                              (framework) => framework.value === values[index]
                            )?.label
                          : "Select framework..."}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[200px] p-0">
                      <Command>
                        <CommandInput placeholder="Search framework..." />
                        <CommandEmpty>No framework found.</CommandEmpty>
                        <CommandGroup className="font-medium">
                          <CommandList>
                            {frameworks.map((framework) => (
                              <CommandItem
                                key={framework.value}
                                value={framework.value}
                                onSelect={(currentValue) => {
                                  handleValueChange(index, currentValue);
                                  handleOpenChange(index, false);
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    values[index] === framework.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {framework.label}
                              </CommandItem>
                            ))}
                          </CommandList>
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <td className="px-4 py-2 border">TataPower</td>
                  <td className="px-4 py-2 border">10</td>
                  <td className="px-4 py-2 border">460</td>
                  <td className="px-4 py-2 border">4600</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </DashboardLayout>
  );
}
