import React, { useState, useRef, useEffect } from "react";

const ImprovedExcelLikeTable = () => {
  const [data, setData] = useState([
    { id: 1, A: "", B: "", C: "", D: "" },
    { id: 2, A: "", B: "", C: "", D: "" },
    { id: 3, A: "", B: "", C: "", D: "" },
  ]);
  const [activeCell, setActiveCell] = useState({ row: 0, col: "A" });
  const [editValue, setEditValue] = useState("");
  const [selection, setSelection] = useState({ start: null, end: null });
  const inputRef = useRef(null);

  const columns = [
    "Symbol",
    "Order Type",
    "Product Type",
    "Segment",
    "Type",
    "Quantity",
    "Ltp",
    "Price",
    "Total Value",
    "Advised Range",
    "Rationale",
  ];

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [activeCell]);

  useEffect(() => {
    const handleCopy = (e) => {
      if (selection.start && selection.end) {
        e.preventDefault();
        const copyData = getCopyData();
        e.clipboardData.setData("text/plain", copyData);
      }
    };

    const handlePaste = (e) => {
      e.preventDefault();
      const pasteData = e.clipboardData.getData("text");
      handlePasteData(pasteData);
    };

    document.addEventListener("copy", handleCopy);
    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("paste", handlePaste);
    };
  }, [selection, data]);

  const handleCellClick = (row, col, e) => {
    if (e.shiftKey && selection.start) {
      setSelection({ ...selection, end: { row, col } });
    } else {
      setSelection({ start: { row, col }, end: { row, col } });
    }
    setActiveCell({ row, col });
    setEditValue(data[row][col]);
  };

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleInputBlur = () => {
    updateCellValue();
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      updateCellValue();
      moveActiveCell("down");
    } else if (e.key === "Tab") {
      e.preventDefault();
      updateCellValue();
      moveActiveCell("right");
    } else if (
      ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)
    ) {
      e.preventDefault();
      updateCellValue();
      moveActiveCell(e.key.replace("Arrow", "").toLowerCase());
    }
  };

  const updateCellValue = () => {
    const { row, col } = activeCell;
    const newData = [...data];
    newData[row] = { ...newData[row], [col]: editValue };
    setData(newData);
  };

  const moveActiveCell = (direction) => {
    const { row, col } = activeCell;
    let newRow = row;
    let newCol = columns.indexOf(col);

    switch (direction) {
      case "up":
        newRow = Math.max(0, row - 1);
        break;
      case "down":
        newRow = Math.min(data.length - 1, row + 1);
        break;
      case "left":
        newCol = Math.max(0, columns.indexOf(col) - 1);
        break;
      case "right":
        newCol = Math.min(columns.length - 1, columns.indexOf(col) + 1);
        break;
      default:
        break;
    }

    setActiveCell({ row: newRow, col: columns[newCol] });
    setEditValue(data[newRow][columns[newCol]]);
    setSelection({
      start: { row: newRow, col: columns[newCol] },
      end: { row: newRow, col: columns[newCol] },
    });
  };

  const addRow = () => {
    const newRow = { id: data.length + 1, A: "", B: "", C: "", D: "" };
    setData([...data, newRow]);
  };

  const getCopyData = () => {
    const startRow = Math.min(selection.start.row, selection.end.row);
    const endRow = Math.max(selection.start.row, selection.end.row);
    const startCol = Math.min(
      columns.indexOf(selection.start.col),
      columns.indexOf(selection.end.col)
    );
    const endCol = Math.max(
      columns.indexOf(selection.start.col),
      columns.indexOf(selection.end.col)
    );

    return data
      .slice(startRow, endRow + 1)
      .map((row) =>
        columns
          .slice(startCol, endCol + 1)
          .map((col) => row[col])
          .join("\t")
      )
      .join("\n");
  };

  const handlePasteData = (pasteData) => {
    const pasteRows = pasteData.split("\n").map((row) => row.split("\t"));
    const { row: startRow, col: startCol } = selection.start;
    const newData = [...data];

    pasteRows.forEach((pasteRow, rowIndex) => {
      const currentRow = startRow + rowIndex;
      if (currentRow >= newData.length) {
        newData.push({ id: newData.length + 1, A: "", B: "", C: "", D: "" });
      }
      pasteRow.forEach((value, colIndex) => {
        const currentCol = columns[columns.indexOf(startCol) + colIndex];
        if (currentCol) {
          newData[currentRow][currentCol] = value;
        }
      });
    });

    setData(newData);
  };

  const getCellStyle = (rowIndex, col) => {
    if (!selection.start || !selection.end) return {};

    const startRow = Math.min(selection.start.row, selection.end.row);
    const endRow = Math.max(selection.start.row, selection.end.row);
    const startCol = Math.min(
      columns.indexOf(selection.start.col),
      columns.indexOf(selection.end.col)
    );
    const endCol = Math.max(
      columns.indexOf(selection.start.col),
      columns.indexOf(selection.end.col)
    );

    if (
      rowIndex >= startRow &&
      rowIndex <= endRow &&
      columns.indexOf(col) >= startCol &&
      columns.indexOf(col) <= endCol
    ) {
      return { backgroundColor: "rgba(0, 123, 255, 0.1)" };
    }

    return {};
  };

  const exportData = () => {
    const csvContent = data
      .map((row) => columns.map((col) => row[col]).join(","))
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="improved-excel-like-table">
      <table className="border-collapse border border-gray-400 w-full">
        <thead>
          <tr>
            <th className="border border-gray-400 p-2"></th>
            {columns.map((col) => (
              <th key={col} className="border border-gray-400 p-2">
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={row.id}>
              <td className="border border-gray-400 p-2">{rowIndex + 1}</td>
              {columns.map((col) => (
                <td
                  key={`${row.id}-${col}`}
                  className="border border-gray-400 p-2"
                  onClick={(e) => handleCellClick(rowIndex, col, e)}
                  style={getCellStyle(rowIndex, col)}
                >
                  {activeCell.row === rowIndex && activeCell.col === col ? (
                    <input
                      ref={inputRef}
                      value={editValue}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      onKeyDown={handleInputKeyDown}
                      className="w-full h-full outline-none"
                    />
                  ) : (
                    row[col]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex space-x-4">
        <button
          onClick={addRow}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Add Row
        </button>
        <button
          onClick={exportData}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Export CSV
        </button>
      </div>
    </div>
  );
};

export default ImprovedExcelLikeTable;
