import * as React from "react";
import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";
import { XIcon } from "lucide-react";

export function IgnoreTradeModal({
  openIgnoreTradeModel,
  setOpenIgnoreTradeModel,
  handleIgnoredTrades,
  stockIgnoreId,
  ignoreText,
  setIgnoreText,
  ignoreLoading,
  style,
}) {
  const isDesktop = useMediaQuery("(min-width: 830px)");

  if (isDesktop) {
    return (
      <Dialog open={openIgnoreTradeModel} onOpenChange={setOpenIgnoreTradeModel}>

        <DialogContent className=" max-w-[520px] px-[40px] animate-modal">
          
          <div className="flex flex-col  items-center py-4">
            <div className="text-[20px] font-medium font-poppins text-center">
              Are you sure you want to ignore this investment advice?
            </div>

            <div className={`mt-3 ${style.inputStartDiv}`}>
              <input
                id="clientCode"
                placeholder="Reason for Ignoring (Optional)"
                type="text"
                value={ignoreText}
                className={`${style.inputBox}`}
                onChange={(e) => setIgnoreText(e.target.value)}
              />
              <label
                htmlFor="clientCode"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                Reason for Ignoring (Optional)
              </label>
            </div>
          </div>
          <DialogFooter className="flex">
            <div
              onClick={() => setOpenIgnoreTradeModel(false)}
              className="w-full bg-[#ffffff] flex items-center justify-center  text-black text-[18px] font-medium font-poppins border-2 border-[#000000]/20 rounded-md py-3 cursor-pointer  transition-all duration-150 ease-linear"
            >
              Cancel
            </div>

            <div
              onClick={() => handleIgnoredTrades(stockIgnoreId)}
              className="w-full flex items-center justify-center bg-red-500 text-white text-[18px] font-medium font-poppins rounded-md py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
            >
              {ignoreLoading === true ? <LoadingSpinner /> : "Ignore Advice"}
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={openIgnoreTradeModel} onOpenChange={setOpenIgnoreTradeModel}>
      <DrawerContent className="focus:outline-none max-h-[500px] md:min-h-[300px]">
      <div onClick={() => setOpenIgnoreTradeModel(false)} className="absolute top-4 right-3 flex items-center justify-center ">
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>
     
       <div className="p-4 h-full overflow-y-auto">
          <div className="flex flex-col  items-center mt-4">
            <div className="text-[20px] font-medium font-poppins text-center">
              Are you sure you want to ignore this investment advice?
            </div>
      
            <div className={`mt-3 ${style.inputStartDiv}`}>
              <input
                id="clientCode"
                placeholder="Reason for Ignoring (Optional)"
                type="text"
                value={ignoreText}
                className={`${style.inputBox}`}
                onChange={(e) => setIgnoreText(e.target.value)}
              />
              <label
                htmlFor="clientCode"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                Reason for Ignoring (Optional)
              </label>
            </div>
          </div>
        </div>
       
        <DrawerFooter className="pt-2 flex flex-col  w-full">
          <div
            onClick={() => handleIgnoredTrades(stockIgnoreId)}
            className="w-full flex items-center justify-center bg-red-500 text-white text-[18px] font-medium font-poppins rounded-md py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
          >
            {ignoreLoading === true ? <LoadingSpinner /> : "Ignore Advice"}
          </div>
         
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
