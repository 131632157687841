import React, { useEffect, useState } from "react";

import AdminDashboardLayout from "../AdminDashboardLayout";
import fetchAdminData from "./AdminData";
import { ChevronLeftIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const TermsAndConditionsByAdvisor = () => {
  const [data, setData] = useState();
  const [user] = useAuthState(auth);

  useEffect(() => {
    const getData = async () => {
      const newData = await fetchAdminData();
      // console.log("New",newData?.data)
      setData(newData?.data);
    };

    getData();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const termsData = data?.termsAndConditions
    ?.split(/\n{1,}/)
    ?.map((item) => item.trim())
    ?.filter((item) => item.length > 0)
    ?.map((item) => ({ text: item }));

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className="min-h-screen w-full items-center justify-center bg-[#F9F9F9] ">
          <div className="border-[1px] border-[#000000]/10 px-[20px]  sm:px-[30px] py-[14px] lg:px-20 lg:py-3 flex items-center justify-between">
            <h2 className=" font-sans text-[18px] sm:text-[24px] md:text-[28px] font-bold md:leading-[60px] text-left ">
              Terms & Conditions by Advisor
            </h2>
            {!isLoading && user === null && (
              <Link
                to="/"
                className=" bg-[#000000] text-white text-[16px] md:text-[18px] font-medium font-poppins rounded-md px-6 py-[6px] md:px-8 md:py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
              >
                Login
              </Link>
            )}
          </div>

          <ul className="mt-1 space-y-4 list-decimal list-inside px-[20px]  sm:px-[30px] pb-[10px] lg:px-20  lg:pb-3">
            {data &&
              termsData?.map((item, index) => (
                <div key={index} className="font-semibold text-[#161515CC]">
                  <p className="font-poppins text-[16px] font-normal leading-[24px] text-left mt-2">
                    {item.text}
                  </p>
                </div>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default TermsAndConditionsByAdvisor;
