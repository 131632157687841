import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAew-j90PzL4CmVFstlm3nAH_XybUPK50U",
  authDomain: "alphaquark-5d09c.firebaseapp.com",
  projectId: "alphaquark-5d09c",
  storageBucket: "alphaquark-5d09c.appspot.com",
  messagingSenderId: "350403736178",
  appId: "1:350403736178:web:56cd72d7d30235e02688eb",
  measurementId: "G-JHH75F1YFX",
};

const app = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

const db = app.firebase.firestore();
const auth = app.firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
export { db, auth, provider };
