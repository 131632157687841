import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import AdminEmptyState from "../AdminHome/AdminEmptyState";
import server from "../../utils/serverConfig";

const style = {
  selected:
    "   text-[16px]  text-black font-bold  border-b-[3px] border-black cursor-pointer",
  unselected: "   font-medium  text-[16px]  text-[#000000]/40 cursor-pointer",
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full   px-3 py-3  bg-white text-[16px]  peer text-gray-900 placeholder:text-gray-400 placeholder:font-normal   font-medium rounded-md mt-1.5 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
  selected_2:
    "flex items-center text-[14px] sm:text-[16px] leading-[14px] lg:text-[18px]  font-poppins text-black font-semibold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected_2:
    "flex items-center text-[14px] sm:text-[16px] leading-[14px] font-medium font-poppins lg:text-[18px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const AdminSettings = ({ userId, adminEmail, adminData, getUserDeatils }) => {
  const [disclaimer, setDisclaimer] = useState("");
  const [termsAndConditions, settermsAndConditions] = useState("");
  const [rationale, setRationale] = useState("");
  const [selectedTab, setSelectedTab] = useState("disclaimer");

  useEffect(() => {
    if (adminData) {
      if (adminData?.disclaimer !== "" || adminData?.disclaimer !== null) {
        setDisclaimer(adminData?.disclaimer);
      } else {
        setDisclaimer("");
      }
      if (
        adminData?.termsAndConditions !== "" ||
        adminData?.termsAndConditions !== null
      ) {
        settermsAndConditions(adminData?.termsAndConditions);
      } else {
        settermsAndConditions("");
      }
      if (adminData?.rationale !== "" || adminData?.rationale !== null) {
        setRationale(adminData?.rationale);
      } else {
        setRationale("");
      }
    }
  }, [userId]);

  const handleAddDisclaimer = async () => {
    const payload = {
      email: adminEmail,
      disclaimer: disclaimer,
    };

    console.log("Sending data:", payload);

    try {
      // Send a POST request to the server
      const response = await fetch(
        `${server.server.baseUrl}api/update-terms-conditions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the request was successful
      if (response.ok) {
        const result = await response.json();
        console.log("Response from server:", result);
        getUserDeatils();
        toast.success("Disclaimer updated successfully !!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#16a085",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "16px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      } else {
        console.error("Failed to update:", response.statusText);
        toast.error("Failed to update disclaimer !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error sending data:", error.message);
      toast.error("Error in updating disclaimer !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleAddTerms = async () => {
    const payload = {
      email: adminEmail,
      termsAndConditions: termsAndConditions,
    };

    console.log("Sending data:", payload);

    try {
      // Send a POST request to the server
      const response = await fetch(
        `${server.server.baseUrl}api/update-terms-conditions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the request was successful
      if (response.ok) {
        const result = await response.json();
        console.log("Response from server:", result);
        getUserDeatils();
        toast.success("Terms & Conditions updated successfully !!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#16a085",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "16px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      } else {
        console.error("Failed to update:", response.statusText);
        toast.error("Failed to update terms & consitions !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error sending data:", error.message);
      toast.error("Failed in updating terms & conditions !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleAddRationale = async () => {
    const payload = {
      email: adminEmail,
      rationale: rationale,
    };

    console.log("Sending data:", payload);

    try {
      // Send a POST request to the server
      const response = await fetch(
        `${server.server.baseUrl}api/update-terms-conditions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the request was successful
      if (response.ok) {
        const result = await response.json();
        console.log("Response from server:", result);
        getUserDeatils();

        toast.success("Rationale updated successfully !!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#16a085",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "16px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      } else {
        console.error("Failed to update:", response.statusText);
        toast.error("Failed to update rationale !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error sending data:", error.message);
      toast.error("Error in updating rationale !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  return (
    <div className="  md:min-h-screen w-full items-center justify-center bg-[#F9F9F9] ">
      <Toaster position="top-center" reverseOrder={true} />
      <div className="px-4 lg:px-12 ">
        <div className="text-balck text-[22px] leading-[30px] py-3 font-sans font-bold">
          Admin Settings
        </div>
      </div>

      <div className=" sm:space-x-6 flex flex-row lg:px-8   border-b border-[#000000]/10  h-[50px] lg:h-[50px] font-poppins">
        <div
          className={`
                  ${
                    selectedTab === "disclaimer"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                 px-[14px] sm:px-4 lg:min-w-[120px] justify-center`}
          onClick={() => {
            setSelectedTab("disclaimer");
          }}
        >
          Disclaimer
        </div>
        <div
          className={`
                  ${
                    selectedTab === "terms"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                 px-[14px] sm:px-4 lg:min-w-[120px] justify-center `}
          onClick={() => {
            setSelectedTab("terms");
          }}
        >
          T&C by Advisor
        </div>
        <div
          className={`
                  ${
                    selectedTab === "rationale"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                 px-[14px] sm:px-4 lg:min-w-[120px] justify-center `}
          onClick={() => {
            setSelectedTab("rationale");
          }}
        >
          Rationale
        </div>
      </div>

      {selectedTab === "disclaimer" && (
        <div className="flex flex-col space-y-4   max-w-[1000px] py-4 px-8">
          <div className="flex flex-col space-y-4">
            <label className="flex flex-col space-y-1 col-span-2">
              <textarea
                value={disclaimer}
                name="disclaimer"
                onChange={(e) => setDisclaimer(e.target.value)}
                className=" max-h-[calc(100vh-280px)] sm:max-h-[calc(100vh-240px)] md:max-h-[calc(100vh-180px)] custom-scroll w-full p-2 border rounded mb-4 text-[16px] font-medium placeholder:font-normal font-poppins "
                placeholder="Enter your disclaimer messaage here..."
                rows="22"
              ></textarea>
            </label>
          </div>

          <div
            onClick={handleAddDisclaimer}
            className=" w-full sm:w-[220px] text-center bg-[#000000] text-white text-[16px] font-medium font-poppins rounded-md px-8 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
          >
            Update Disclaimer
          </div>
        </div>
      )}

      {selectedTab === "terms" && (
        <div className="flex flex-col space-y-4   max-w-[1000px] py-4 px-8">
          <div className="flex flex-col space-y-4">
            <label className="flex flex-col space-y-1 col-span-2">
              <textarea
                value={termsAndConditions}
                name="terms"
                onChange={(e) => settermsAndConditions(e.target.value)}
                className="w-full max-h-[calc(100vh-280px)] sm:max-h-[calc(100vh-240px)] md:max-h-[calc(100vh-180px)] custom-scroll p-2 border rounded mb-4 text-[16px] font-medium placeholder:font-normal font-poppins "
                placeholder="Enter your terms & condition messaage here..."
                rows="22"
              ></textarea>
            </label>
          </div>

          <div
            onClick={handleAddTerms}
            className=" w-full sm:w-[300px] text-center bg-[#000000] text-white text-[16px] font-medium font-poppins rounded-md px-8 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
          >
            Update Terms & Conditions
          </div>
        </div>
      )}

      {selectedTab === "rationale" && (
        <div className="flex flex-col space-y-4   max-w-[1000px] py-4 px-8">
          <div className="flex flex-col space-y-4">
            <label className="flex flex-col space-y-1 col-span-2">
              <textarea
                value={rationale}
                name="rationale"
                onChange={(e) => setRationale(e.target.value)}
                className=" max-h-[calc(100vh-280px)] sm:max-h-[calc(100vh-240px)] md:max-h-[calc(100vh-180px)] custom-scroll w-full p-2 border rounded mb-4 text-[16px] font-medium placeholder:font-normal font-poppins "
                placeholder="Enter your rationale messaage here..."
                rows="22"
              ></textarea>
            </label>
          </div>

          <div
            onClick={handleAddRationale}
            className=" w-full sm:w-[205px] text-center bg-[#000000] text-white text-[16px] font-medium font-poppins rounded-md px-8 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
          >
            Update Rationale
          </div>
        </div>
      )}


    </div>
  );
};

export default AdminSettings;
