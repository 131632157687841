export default function formatCurrency(amount) {
    // Convert the amount to a string and split it at the decimal point
    let [integerPart, decimalPart] = amount.toString().split('.');

    // Handle the last three digits separately (they won't have a comma)
    let lastThreeDigits = integerPart.slice(-3);
    let restDigits = integerPart.slice(0, -3);

    // Add commas after every two digits in the rest of the number
    if (restDigits !== '') {
        restDigits = restDigits?.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',';
    }

    // Combine the formatted parts
    integerPart = restDigits + lastThreeDigits;

    // Combine the integer part with the decimal part (if any)
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
}