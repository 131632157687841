"use client";

import { XIcon } from "lucide-react";
import React, { useState } from "react";

const EditSubscriptionModal = ({
  clientId,
  subscriptionToEdit,
  onClose,
  onUpdate,
}) => {
  const [editedSubscription, setEditedSubscription] =
    useState(subscriptionToEdit);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedSubscription((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = () => {
    console.log("Updated Client Details:", editedSubscription);
    onUpdate(clientId,subscriptionToEdit?.subId,editedSubscription);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative">
        <div
          className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={onClose}
        >
          <XIcon className="w-6 h-6 mr-2" />
        </div>
        <div className="bg-white  rounded-lg w-[650px] px-8 py-8">
          <h3 className="text-lg font-semibold mb-4">Edit Client Details</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 font-poppins">
            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Start Date
              </span>
              <input
                type="text"
                name="startDate"
                value={editedSubscription.startDate}
                onChange={handleChange}
                placeholder="Start Date"
                className="p-2 border rounded font-medium"
              />
            </label>

            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Plan Name
              </span>
              <input
                type="text"
                name="plan"
                value={editedSubscription.plan}
                onChange={handleChange}
                placeholder="Plan Name"
                className="p-2 border rounded font-medium"
              />
            </label>

            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Capital
              </span>
              <input
                type="text"
                name="capital"
                value={editedSubscription.capital}
                onChange={handleChange}
                placeholder="Capital"
                className="p-2 border rounded font-medium"
              />
            </label>

            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Charges
              </span>
              <input
                type="text"
                name="charges"
                value={editedSubscription.charges}
                onChange={handleChange}
                placeholder="Charges"
                className="p-2 border rounded font-medium"
              />
            </label>

            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Invoice
              </span>
              <input
                type="text"
                name="invoice"
                value={editedSubscription.invoice}
                onChange={handleChange}
                placeholder="Invoice"
                className="p-2 border rounded font-medium"
              />
            </label>

            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Expiry
              </span>
              <input
                type="text"
                name="expiry"
                value={editedSubscription.expiry}
                onChange={handleChange}
                placeholder="Expiry"
                className="p-2 border rounded font-medium"
              />
            </label>
            
          </div>

          <div className="mt-8 text-[18px] font-normal font-poppins text-center ">
            <div className="grid grid-cols-2 gap-x-4 gap-y-2">
              <div
                onClick={onClose}
                className=" bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[16px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer"
              >
                Cancel
              </div>
              <div
                onClick={handleUpdate}
                className=" bg-[#000000] text-white text-[16px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
              >
                Update Subscription
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSubscriptionModal;
