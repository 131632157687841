import React from "react";
import PositionCard from "./PositionCard";
import { Link } from "react-router-dom";

const OpenPositions = ({ data, getLTPForSymbol }) => {
  return (
    <section className="h-full lg:h-[calc(100vh-210px)] lg:mt-2 ">
      {data.length !== 0 ? (
        <div className=" bg-white hidden lg:block w-full h-[calc(100vh-380px)] pb-4 lg:pb-2 lg:h-[calc(100vh-280px)] overflow-auto custom-scroll">
          <table className={`w-full`}>
            <thead className="bg-[#ffffff] text-sm text-left sticky top-0  ">
              <tr className="border-b-[1px]  border-[#000000]/10">
                <th className=" text-[12px] min-w-[140px] lg:text-[13px]  text-[#000000]/60 font-poppins font-medium text-left px-3 py-2 lg:py-3 lg:pl-[60px]">
                  Stock
                </th>
                <th className="text-[12px] lg:text-[13px] text-center text-[#000000]/60 font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                  Qty
                </th>
                <th className=" text-[12px] lg:text-[13px]  text-center text-[#000000]/60 font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                  LTP
                </th>
                <th className=" text-[12px] min-w-[100px] lg:text-[13px]  text-center text-[#000000]/60 font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                  Avg. Cost
                </th>
                <th className=" text-[12px] lg:text-[13px]  text-center text-[#000000]/60 font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                  Invested
                </th>
                <th className=" text-[12px] lg:text-[13px]  text-center text-[#000000]/60 font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                  Type
                </th>
                <th className=" text-[12px] min-w-[125px] lg:text-[13px]  text-[#000000]/60 text-center font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                  Unrealized P&L
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((ele, i) => {
                const ltp = getLTPForSymbol(ele?.Symbol);
                const value = ltp - ele?.tradedPrice;
                const pnl = value * ele?.tradedQty;
                return (
                  <tr
                    key={i}
                    className="border-b-[1px]  z-10 border-[#000000]/10"
                  >
                    <td className="text-[15px]  text-[#000000] font-poppins font-normal text-left px-3 py-2 lg:py-3 lg:pl-[60px] ">
                      {ele?.Symbol ? ele?.Symbol : "-"}
                    </td>
                    <td className="text-[15px]  text-[#000000] font-poppins font-normal text-center px-3 py-2 lg:py-3 lg:px-5 ">
                      {ele?.tradedQty ? ele?.tradedQty : "-"}
                    </td>
                    <td className="text-[15px] text-[#000000] font-poppins font-normal text-center px-3 py-2 lg:py-3 lg:px-5 ">
                      ₹{getLTPForSymbol(ele?.Symbol)}
                    </td>
                    <td className="text-[15px]  text-[#000000] font-poppins font-normal text-center px-3 py-2 lg:py-3 lg:px-5 ">
                      ₹{ele?.tradedPrice ? ele?.tradedPrice : "-"}
                    </td>
                    <td className="text-[15px] text-[#000000] font-poppins font-normal text-center px-3 py-2 lg:py-3 lg:px-5 ">
                      <span>
                        {" "}
                        ₹{" "}
                        {ele?.tradedQty && ele?.tradedPrice
                          ? ele?.tradedQty * ele?.tradedPrice
                          : "-"}
                      </span>
                    </td>
                    <td className="text-[15px]  text-[#000000] font-poppins font-normal text-center px-3 py-2 lg:py-3 lg:px-5 ">
                      {ele?.Type.trim().toLowerCase() === "buy" ? (
                        <span className="text-[#16A085] font-semibold">
                          {ele?.Type}
                        </span>
                      ) : (
                        ele?.Type.trim().toLowerCase() === "sell" && (
                          <span className="text-[#E43D3D] font-semibold">
                            {ele?.Type}
                          </span>
                        )
                      )}
                    </td>
                    <td className="text-[15px] last-of-type:border-none text-[#000000] font-poppins font-normal text-center  px-3 py-2 lg:py-3 lg:px-5">
                      {ele?.Symbol ? (
                        <span>
                          {pnl > 0 ? (
                            <span className="text-[#16A085] font-semibold">
                              +₹
                              {pnl ? pnl.toFixed(2) : "-"}
                            </span>
                          ) : pnl < 0 ? (
                            <span className="text-[#E43D3D] font-semibold">
                              {" "}
                              -₹{Math.abs(pnl.toFixed(2))}
                            </span>
                          ) : (
                            <span className="font-semibold">0</span>
                          )}
                        </span>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className=" hidden lg:block w-full h-full max-h-[calc(100vh-360px)] lg:h-[calc(100vh-300px)] overflow-auto custom-scroll mb-4">
          <div className="pt-40 flex flex-col items-center justify-center">
            <div className="text-xl lg:text-3xl font-semibold font-poppins flex items-center justify-center">
              No Active Positions
            </div>
            <p className="text-[14px] lg:text-lg font-normal text-[#000000]/60 font-sans flex items-center justify-center">
              Start placing orders to view your active positions here
            </p>
            <Link
              to="/stock-recommendation"
              className="mt-4 py-2 px-6 bg-black text-[18px] lg:text-lg  text-white font-medium rounded-md cursor-pointer"
            >
              View Trade Advices
            </Link>
          </div>
        </div>
      )}

      <div className="lg:hidden flex flex-col w-full h-full">
        {data.length !== 0 ? (
          data.map((ele, i) => {
            const ltp = getLTPForSymbol(ele?.Symbol);
            const value = ltp - ele?.tradedPrice;
            const pnl = value * ele?.tradedQty;
            return (
              <PositionCard
                key={i}
                qty={ele?.tradedQty}
                avgPrice={ele?.buyPrice}
                symbol={ele?.Symbol}
                pnl={ele?.Symbol ? pnl : 0}
                totalInvested={ele?.tradedQty * ele?.buyPrice}
                ltp={ltp}
              />
            );
          })
        ) : (
          <div className="pt-16 px-10 flex flex-col items-center justify-center">
            <div className="text-xl lg:text-3xl font-semibold font-poppins flex items-center justify-center">
              No Active Positions
            </div>
            <p className="mt-1 text-[14px] text-center lg:text-lg font-normal text-[#000000]/60 font-sans flex items-center justify-center">
              Start placing orders to view your active positions here
            </p>
            <Link
              to="/stock-recommendation"
              className="mt-4 py-2 px-6 bg-black text-[15px] lg:text-lg  text-white font-medium rounded-md cursor-pointer"
            >
              View Trade Advices
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default OpenPositions;
