import { XIcon } from "lucide-react";
import React, { useState } from "react";

const DeleteModal = ({ client, onClose, onDelete }) => {
  return (
    <div className="fixed inset-0 flex items-center font-poppins justify-center bg-black bg-opacity-50 z-50">
      <div className="relative">
        <div
          className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={onClose}
        >
          <XIcon className="w-6 h-6 mr-2" />
        </div>
        <div className=" bg-white p-6 rounded-lg w-[500px] ">
          <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
          <p>
            Are you sure you want to delete client
            <span className="font-semibold ml-1">{client.clientName}</span>?
          </p>

          <div className="mt-6 text-[18px] font-normal font-poppins text-center ">
            <div className="grid grid-cols-2 gap-x-4 gap-y-2">
              <div
                onClick={onClose}
                className=" bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer"
              >
                Cancel
              </div>
              <div
                onClick={() => {
                  onDelete(client);
                  onClose();
                }}
                className=" bg-[#ff0000] text-white text-[18px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
              >
                Delete
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
