import React, { useState, useEffect, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { auth } from "../../firebase";
import { CurrentUserData } from "../../utils/data";
import server from "../../utils/serverConfig";
import moment from "moment";
import HistoryIcon from "../../assests/history_icon.svg";
import TradeHistoryTable from "./TradeHistoryTable";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

import { DatePicker } from "../../components/ui/date-picker";

// Example data array

const TradeHistory = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const { data: userHero } = CurrentUserData(userEmail);
  const userDetails = userHero && userHero.data.User;

  const broker = userDetails && userDetails?.user_broker;
  const [show, setShow] = useState(false);
  const [filterData, setFilterData] = useState("view-all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredeData, setFilteredData] = useState([]);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  const getAllTrades = () => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/trade-history/trade-history-by-client?email=${userEmail}`,
    };

    axios
      .request(config)
      .then((response) => {
        const stockData = response?.data?.trades;
        setData(stockData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!data) return;

    // Copy of data to avoid mutating the original array
    let raw = [...data];

    // Sort the trade history by date in descending order

    // Filter by profit, loss, or show all based on filterData
    let filteredTrades = [];
    if (filterData === "profit") {
      filteredTrades = raw.filter((trade) => trade.pnl > 0); // Profit trades
    } else if (filterData === "loss") {
      filteredTrades = raw.filter((trade) => trade.pnl < 0); // Loss trades
    } else {
      filteredTrades = raw; // View all
    }

    // Further filter by startDate and endDate if they are provided
    if (startDate) {
      filteredTrades = filteredTrades.filter((trade) =>
        moment(trade.sell[0]?.exitDate).isSameOrAfter(startDate, "day")
      );
    }
    if (endDate) {
      filteredTrades = filteredTrades.filter((trade) =>
        moment(trade).isSameOrBefore(endDate, "day")
      );
    }

    // Update the filtered data
    setFilteredData(filteredTrades);
  }, [filterData, startDate, endDate, data]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1700);

    return () => clearTimeout(timer);
  }, []);

  const dropdownOption = ["View All Trades", "Profit Trades", "Loss Trades"];
  const handleSelectOption = (value) => {
    if (value === "View All Trades") {
      setFilterData("view-all");
    } else if (value === "Profit Trades") {
      setFilterData("profit");
    } else if (value === "Loss Trades") {
      setFilterData("loss");
    }
  };

  return (
    <>
      {loading === true ? (
        <div className="flex flex-row justify-center items-center  w-full lg:min-h-screen  bg-[#f9f9f9]">
          <LoadingSpinner />
        </div>
      ) : data.length !== 0 ? (
        <div className="flex flex-col relative  w-full lg:min-h-screen  bg-[#f9f9f9] ">
          <div className="flex flex-col h-[160px] lg:h-[160px] ">
            <div className="px-6">
              <div className="text-balck text-[22px] lg:text-[22px] leading-[30px] mt-4 font-sans font-bold">
                Trade History
              </div>
              <div className="text-[11px] lg:text-sm text-[#000000]/70 leading-[20px] font-light font-poppins">
                Easily track and assess closed trades and their outcomes. To
                view Open Positions check your live portfolio.
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-0 lg:items-center mt-1 px-4 lg:mt-6 lg:px-4 w-full  ">
              <div className="grid grid-cols-4 gap-x-6 text-[14px] rounded-lg px-2 py-2 lg:px-4  lg:py-2   ">
                <div>
                  <DatePicker
                    date={startDate}
                    setDate={setStartDate}
                    placeholder={"Start Date"}
                  />
                </div>
                <div>
                  <DatePicker
                    date={endDate}
                    setDate={setEndDate}
                    placeholder={"End Date"}
                  />
                </div>
                <div className="">
                  <Select
                    defaultValue={dropdownOption[0]}
                    onValueChange={(value) => handleSelectOption(value)}
                  >
                    <SelectTrigger className="py-2.5 bg-[#F9F9F9]  text-[16px] font-poppins font-medium border-none ring-1 hover:ring-1 ring-gray-200  hover:ring-[#D9D9D9] focus:outline-none focus:ring-1 focus:ring-[#D9D9D9]">
                      <SelectValue placeholder={"Select Filter"} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {dropdownOption?.map((option, index) => (
                          <SelectItem
                            className="font-poppins font-medium capitalize text-[14px] "
                            key={index}
                            value={option}
                          >
                            {option}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                <div
                  onClick={() => {
                    setFilterData("view-all");
                    setStartDate("");
                    setEndDate("");
                  }}
                  className="w-[150px] flex flex-row items-center justify-center px-10 py-2  lg:px-6 lg:py-1.5 bg-[#000000] text-white text-[16px] font-poppins font-normal rounded-md cursor-pointer"
                >
                  Clear
                </div>
              </div>
            </div>
          </div>

          {data && data.length !== 0 ? (
            <TradeHistoryTable data={filteredeData} broker={broker} />
          ) : null}
        </div>
      ) : (
        isLoading === true &&
        loading === true && (
          <div className="flex flex-col items-center justify-center space-y-6 relative  w-full lg:min-h-screen  bg-[#f9f9f9] py-6 ">
            <div className="flex pl-[8px] items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
              <img
                src={HistoryIcon}
                alt="History Icon"
                className="w-[60px] lg:w-[80px]"
              />
            </div>
            <div className="flex flex-col space-y-3 lg:space-y-5 items-center px-6 lg:w-[800px]">
              <div className="text-black text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                No Trade History
              </div>
              <div className="md:max-w-[650px] text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[24px] font-normal font-poppins px-[10px] lg:px-[60px]">
                No Trades have been recorded yet. When you complete a trade, it
                will be listed here.
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default TradeHistory;
