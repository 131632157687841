import React from "react";
import ViewTradeInconsistencyModals from "./ViewTradeInconsistencyModal";
import { FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import { EyeIcon, MailIcon, MessageSquareMoreIcon } from "lucide-react";

const TradeInconsistencyCard = ({
  userEmail,
  emailCount,
  tradeInconsistencyModal,
  setTradeInconsistencyModal,
  selectedTab,
  setSelectedTab,
  filteredInconsistencyTrades,
  filteredFailuresTrades,
  filteredMissedTrades,
  setSelectedUser,
  selectedUser,
  userEmailCount,
  setUserEmailCount,
}) => {
  return (
    <>
      <div className="grid grid-cols-5 font-poppins  hover:bg-[#000000]/5 w-full px-4    py-[10px] border-b-[1px] border-[#000000]/10">
        <div className="flex items-center text-xs sm:text-[15px] font-medium text-[#000000] capitalize col-span-1  ">
          <div
            onClick={() => {
              setTradeInconsistencyModal(true);
              setSelectedUser(userEmail);
              setUserEmailCount(emailCount);
            }}
            className="mr-1 group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]"
          >
            <EyeIcon className="h-5 w-5  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]" />
          </div>
          {emailCount}
        </div>
        <span className="flex items-center text-xs sm:text-[15px]  font-medium text-[#000000] col-span-3 ">
          {userEmail}
        </span>
        <div className="flex  items-center col-span-1 pl-2 sm:pl-0">
          {/* <span className="  group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]  ">
            <MessageSquareMoreIcon className="h-5 w-5  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]" />
          </span> */}
          <span className="  group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]  ">
            <FaTelegramPlane className="sm:h-5 h-4 sm:w-5 w-4  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]" />
          </span>
          {/* <span className="  group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]  ">
            <FaWhatsapp className="sm:h-5 h-4 sm:w-5 w-4  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]" />
          </span> */}
          <span className="  group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]  ">
            <MailIcon className="sm:h-5 h-4 sm:w-5 w-4  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]" />
          </span>
        </div>
      </div>

      {tradeInconsistencyModal && (
        <ViewTradeInconsistencyModals
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTradeInconsistencyModal={setTradeInconsistencyModal}
          filteredFailuresTrades={
            filteredFailuresTrades && filteredFailuresTrades
          }
          filteredInconsistencyTrades={
            filteredInconsistencyTrades && filteredInconsistencyTrades
          }
          filteredMissedTrades={filteredMissedTrades && filteredMissedTrades}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
          userEmailCount={userEmailCount}
          setUserEmailCount={setUserEmailCount}
        />
      )}
    </>
  );
};

export default TradeInconsistencyCard;
