import React from "react";

import HomePage from "./Home";

import DashboardLayout from "../DashboardLayout";
// import HomePage from "./NewHome";

const UserHomePage = () => {
  return (
    <DashboardLayout>
      <HomePage />
    </DashboardLayout>
  );
};

export default UserHomePage;
