import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import Excel from "../../assests/Icon/Excel.svg";
import GroupListTable from "./GroupListTable";
import { CircleXIcon, PlusIcon, SearchIcon, XIcon } from "lucide-react";
import ExcelIcon from "../../assests/excel-icon.svg";
import FormatDateTime from "../../utils/formatDateTime";
import { CSVLink } from "react-csv";
import ClientListNewTable from "./ClientListNewTable";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-300  hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
  selected_2:
    "flex items-center text-[16px] leading-[14px] lg:text-[18px]  font-poppins text-black font-semibold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected_2:
    "flex items-center text-[16px] leading-[14px] font-medium font-poppins lg:text-[18px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const ClientListNew = ({
  userId,
  allClientsData,
  allGroupsData,
  setIsDataFetching,
  getAllClientsData,
  getAllGroupsData,
  isClientDataLoading,
  isGroupDataLoading,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState("group-list");
  const [newGroupName, setNewGroupName] = useState("");
  const [addGroupModal, setAddGroupModal] = useState(false);

  const openAddGroupModal = () => {
    setAddGroupModal(true);
  };

  const closeAddGroupModal = () => {
    setAddGroupModal(false);
  };

  const handleAddGroupName = async () => {
    const groupData = {
      userId,
      creationDate: FormatDateTime(new Date()),
      groupName: newGroupName?.toLowerCase()?.replace(/ /g, "_"),
    };
    console.log("data", groupData);

    try {
      const response = await fetch(`${server.server.baseUrl}api/add-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(groupData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      getAllGroupsData(userId);
      getAllClientsData(userId);
      closeAddGroupModal();
      setNewGroupName("");

      toast.success("New group added successfully !!", {
        duration: 3000,
        style: {
          background: "white",
          color: "#16a085",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "16px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#16a085",
          secondary: "#FFFAEE",
        },
      });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);

      toast.error("Error in adding new group !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const csvHeaders = [
    { label: "client_id", key: "client_id" },
    { label: "client_name", key: "client_name" },
    { label: "email", key: "email" },
    { label: "phone", key: "phone" },
    { label: "groups", key: "groups" },
    { label: "location", key: "location" },
    { label: "telegram", key: "telegram" },
    { label: "pan", key: "pan" },
    { label: "comments", key: "comments" },
    { label: "subscription_id", key: "subscription_id" },
    { label: "start_date", key: "start_date" },
    { label: "plan", key: "plan" },
    { label: "capital", key: "capital" },
    { label: "charges", key: "charges" },
    { label: "invoice", key: "invoice" },
    { label: "expiry", key: "expiry" },
    { label: "acc_creation_date", key: "acc_creation_date" },
    { label: "updated_at", key: "updated_at" },
  ];

  const flattenClientsData = () => {
    return allClientsData.flatMap((client) => {
      // If the client has subscriptions, map through them
      if (client.subscriptions && client.subscriptions.length > 0) {
        return client.subscriptions.map((subscription) => ({
          client_id: client.clientId,
          client_name: client.clientName,
          email: client.email,
          phone: client.phone,
          groups: client.groups, // Keep groups as an array
          location: client.location,
          telegram: client.telegram,
          pan: client.pan,
          comments: client.comments,
          subscription_id: subscription?.subId || "",
          start_date: subscription?.startDate || "",
          plan: subscription?.plan || "",
          capital: subscription?.capital || "",
          charges: subscription?.charges || "",
          invoice: subscription?.invoice || "",
          expiry: subscription?.expiry || "",
          acc_creation_date: FormatDateTime(client.creationDate),
          updated_at: FormatDateTime(client.updatedAt),
        }));
      }

      // If the client has no subscriptions, return the client data with subscription fields as empty strings
      return {
        client_id: client.clientId,
        client_name: client.clientName,
        email: client.email,
        phone: client.phone,
        groups: client.groups, // Keep groups as an array
        location: client.location,
        telegram: client.telegram,
        pan: client.pan,
        comments: client.comments,
        subscription_id: "",
        start_date: "",
        plan: "",
        capital: "",
        charges: "",
        invoice: "",
        expiry: "",
        acc_creation_date: FormatDateTime(client.creationDate),
        updated_at: FormatDateTime(client.updatedAt),
      };
    });
  };

  const clientDataWithSubscriptions = flattenClientsData();

  const clearSearchInput = () => {
    setSearchQuery("");
  };

  return (
    <div className="max-h-screen w-full items-center justify-center bg-[#ffffff]">
      <Toaster position="top-center" reverseOrder={true} />
      <div className="flex items-center justify-between h-[50px]">
        {/* <div className="flex bg-[#000000]/10 max-w-[300px] p-1 rounded-md">
          <div
            onClick={() => {
              setSelectedTab("group-list");
              clearSearchInput();
            }}
            className={`${
              selectedTab === "group-list"
                ? " bg-white text-[#000000] rounded-md"
                : " bg-transparent text-[#000000]/50"
            } w-[150px] cursor-pointer py-2 flex items-center justify-center font-medium text-[14px] font-poppins `}
          >
            Group List
          </div>
          <div
            onClick={() => {
              setSelectedTab("client-list");
              clearSearchInput();
            }}
            className={`${
              selectedTab === "client-list"
                ? " bg-white text-[#000000] rounded-md"
                : " bg-transparent text-[#000000]/50"
            } w-[150px] cursor-pointer py-2 flex items-center justify-center font-medium text-[14px] font-poppins `}
          >
            Client List
          </div>
        </div> */}

        <div className=" grid grid-cols-2  md:space-x-6 md:flex flex-row h-[50px] lg:h-[50px] font-poppins">
          <button
            className={`
                  ${
                    selectedTab === "group-list"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                 px-4 lg:min-w-[120px] justify-center`}
            onClick={() => {
              setSelectedTab("group-list");
            }}
          >
            Group List
          </button>
          <button
            className={`
                  ${
                    selectedTab === "client-list"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                 px-4 lg:min-w-[120px] justify-center `}
            onClick={() => {
              setSelectedTab("client-list");
            }}
          >
            Client List
          </button>
        </div>

        <div className="flex flex-row items-center justify-end space-x-6 lg:px-8 ">
          <div className="flex items-center space-x- px-2 md:w-[260px] border-b-[1px] border-[#000000]/10 bg-transparent ">
            <SearchIcon className="w-5 h-5 text-[#000000]/50" />
            <input
              placeholder={`Search By "Client Email"`}
              className="w-full bg-transparent py-3 px-4 rounded-md text-[#000000] text-[14px] font-poppins placeholder:text-[#000000]/50"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {searchQuery?.length > 1 && (
              <CircleXIcon
                onClick={clearSearchInput}
                className="w-4 h-4 text-[#000000]/50"
              />
            )}
          </div>

          <CSVLink
            data={clientDataWithSubscriptions}
            headers={csvHeaders}
            filename={`client_data_list_${moment().format(
              "YYYYMMDD_HHmmss"
            )}.csv`}
            className=""
          >
            <button className="flex disabled:bg-[#000000]/20  text-center bg-white border-[1px] border-[#000000]/30 disabled:cursor-not-allowed text-[#000000] text-[16px] font-medium font-poppins rounded-[4px] px-4 py-2 cursor-pointer hover:scale-[1.02] transition-all duration-150 ease-linear">
              <img
                src={ExcelIcon}
                alt="Excel Icon"
                width={25}
                className="mr-2"
              />
              Download Client List
            </button>
          </CSVLink>
        </div>
      </div>

      {selectedTab === "client-list" && (
        <>
          {isClientDataLoading && allClientsData.length === 0 ? (
            <div className="flex  items-center justify-center h-full w-full">
              <LoadingSpinner />
            </div>
          ) : (
            <ClientListNewTable
              userId={userId}
              setIsDataFetching={setIsDataFetching}
              allClientsData={allClientsData}
              allGroupsData={allGroupsData}
              searchQuery={searchQuery}
              getAllClientsData={getAllClientsData}
            />
          )}
        </>
      )}
      {selectedTab === "group-list" && (
        <>
          {isGroupDataLoading && allGroupsData.length === 0 ? (
            <div className="flex  items-center justify-center h-full w-full">
              <LoadingSpinner />
            </div>
          ) : (
            <GroupListTable
              userId={userId}
              allClientsData={allClientsData}
              allGroupsData={allGroupsData}
              getAllGroupsData={getAllGroupsData}
              getAllClientsData={getAllClientsData}
              searchQuery={searchQuery}
              openAddGroupModal={openAddGroupModal}
            />
          )}
        </>
      )}

      {addGroupModal && (
        <div className="fixed inset-0 flex items-center font-poppins justify-center bg-black bg-opacity-50">
          <div className="relative">
            <div
              className="absolute right-2 top-4 text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer"
              onClick={closeAddGroupModal}
            >
              <XIcon className="w-6 h-6 mr-2" />
            </div>
            <div className="bg-white p-6 rounded-lg w-[500px]">
              <h3 className="text-lg font-semibold mb-4">Add New Group </h3>
              <div className="grid grid-cols-1 gap-4 font-poppins">
                <label className="flex flex-col">
                  <input
                    type="text"
                    name="newGroupName"
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                    className="p-2 border rounded"
                  />
                </label>
              </div>
              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={closeAddGroupModal}
                  className="bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-4 py-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddGroupName}
                  className="bg-[#1D1D1D] text-white text-[18px] font-medium font-poppins rounded-md px-4 py-2 hover:scale-[1.04] transition-all duration-150 ease-linear"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientListNew;
