import React, { useState } from "react";
import { Link } from "react-router-dom";
import { cn } from "../utils/utils";
import Logo from "../assests/Logo.jpg";
import { auth } from "../firebase";

import {
  LogOutIcon,
  HistoryIcon,
  IndianRupeeIcon,
  LightbulbIcon,
  FileBarChartIcon,
  XIcon,
  ChevronRightIcon,
  BanIcon,
  CandlestickChartIcon,
  UserCog2Icon,
  GitForkIcon,
} from "lucide-react";

const Icons = {
  home: <FileBarChartIcon className="mr-5 h-5 w-5" />,
  advice: <LightbulbIcon className="mr-5 h-5 w-5" />,
  history: <HistoryIcon className="mr-5 h-5 w-5" />,
  sub: <IndianRupeeIcon className="mr-5 h-5 w-5" />,
  ignore: <BanIcon className="mr-5 h-5 w-5" />,
  userRound: <UserCog2Icon className="mr-5 h-5 w-5" />,
  order: <CandlestickChartIcon className="mr-5 h-5 w-5" />,
  strategy: <GitForkIcon className="mr-5 h-5 w-5 -rotate-90" />,
};

const mobileNavItems = [
  {
    title: "Live Portfolio",
    href: "/",
    path: "/",
    icon: "home",
  },
  {
    title: "Advice",
    href: "/stock-recommendation",
    path: "stock-recommendation",
    icon: "advice",
  },
  {
    title: "Order Details",
    href: "/order-details",
    path: "order-details",
    icon: "order",
  },
  {
    title: "Exited Trade History",
    href: "/trade-history",
    path: "history",
    icon: "history",
  },

  {
    title: "Subscriptions",
    href: "/subscriptions",
    path: "subscriptions",
    icon: "sub",
  },
  // {
  //   title: "Strategy",
  //   href: "/strategy",
  //   path: "strategy",
  //   icon: "strategy",

  // },
  {
    title: "Contact Advisor",
    href: "/contact-advisor",
    path: "contact-advisor",
    icon: "userRound",
  },
];

const MobileNavBar = (
  {
    isOpen,
    onClose,
    openUserProfileModal,
    userDetails,
    adviceTradeData,
    ignoreTradesData,
  },
  props
) => {
  const path = window.location.pathname;
  const [showLogoutModel, setShowLogoutModel] = useState(false);

  const image =
    "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg";
  const email = "test77@gmail.com";

  const userName = "admin";

  var initials;
  var fullName = userDetails && userDetails.name;
  initials =
    fullName &&
    fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();

  if (!mobileNavItems?.length) {
    return null;
  }
  return (
    <div
      className={`fixed inset-0 z-50 ${
        isOpen ? "block " : "hidden"
      } transition ease-in duration-300`}
    >
      {/* Background Overlay */}
      <div
        className={`fixed inset-0 bg-black opacity-40  ${
          isOpen ? "opacity-100" : "opacity-0"
        }`}
        onClick={onClose}
      ></div>

      {/* Drawer */}
      <div
        className={`fixed inset-y-0 left-0 w-full bg-black z-50 transform transition ease-in duration-500   ${
          isOpen ? "translate-x-0 animate-slideInNav" : "-translate-x-full "
        }`}
      >
        <aside
          className={`flex flex-col  transform top-0 left-0 w-full bg-black fixed h-full  ease-in-out transition-all duration-300 z-30   ${
            isOpen
              ? "translate-x-0 animate-slideInNav"
              : "-translate-x-full animate-slideOutNav"
          }`}
        >
          <div
            onClick={onClose}
            className={`absolute   z-100 top-[26px] right-2`}
          >
            <XIcon className="h-6 w-6 md:h-8 md:w-8 text-white" />
          </div>
          <div
            className={`px-2 ${
              userDetails && userDetails.profile_completion === 100
                ? "mb-10"
                : "mb-5"
            } md:mb-10`}
          >
            <div className="flex flex-row space-x-6 items-center px-6 pt-3 md:pt-6">
              <div className="">
                <img src={Logo} alt="Logo" width={45} className="rounded-md" />
              </div>
              <div className="text-[24px] font-medium font-poppins text-[#f2f2f2] leading-[40px] ">
                AltQube
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-1 gap-y-0 overflow-auto pb-[70px]">
            {mobileNavItems.map((item, index) => {
              const Icon = Icons[item.icon];

              return (
                item.href && (
                  <div key={index}>
                    <Link key={index} to={item.disabled ? "/admin" : item.href}>
                      <div
                        className={cn(
                          "group  md:py-5 text-sm md:text-2xl border-l-4   hover:bg-[#ffffff]/20 hover:text-gray-300 text-[15px] text-[#ffffff]/50 font-normal font-poppins leading-[22px] flex items-center px-[30px] py-[14px]",
                          path === item.href
                            ? "bg-[#ffffff]/20 border-l-4border-[#ffffff] text-[#ffffff]"
                            : "border-l-4 border-transparent bg-transparent",
                          item.disabled && "cursor-not-allowed opacity-80"
                        )}
                      >
                        {Icon}
                        <span>{item.title}</span>
                        {item.icon === "advice" &&
                          adviceTradeData &&
                          adviceTradeData.length >= 1 && (
                            <span className="ml-3">
                              ({adviceTradeData && adviceTradeData.length})
                            </span>
                          )}
                        {item.icon === "ignore" &&
                          ignoreTradesData &&
                          ignoreTradesData.length >= 1 && (
                            <span className="ml-3">
                              ({ignoreTradesData && ignoreTradesData.length})
                            </span>
                          )}
                      </div>
                    </Link>
                  </div>
                )
              );
            })}
            <div
              onClick={() => setShowLogoutModel(true)}
              className={cn(
                "group cursor-pointer text-sm border-l-4 border-transparent bg-transparent hover:bg-[#ffffff]/20 hover:text-gray-300 text-[15px] md:text-2xl text-[#ffffff]/50 font-normal font-poppins leading-[22px] flex items-center px-[30px] py-[14px]"
              )}
            >
              <LogOutIcon className="h-5 w-5 text-[#ffffff]/50 group-hover:text-gray-300  mr-5" />
              <span>Logout</span>
            </div>
          </div>

          {userDetails && userDetails.profile_completion !== 100 && (
            <div className="flex flex-col  border-t-[1px] border-[#ffffff]/20 w-full px-8 py-2">
              <div className="flex  justify-between items-center  h-[60px] w-full ">
                <div className="flex  space-x-3 items-center   text-white text-center   ">
                  {userDetails &&
                  userDetails?.image_url &&
                  userDetails?.image_url !== "" &&
                  userDetails?.image_url !== null ? (
                    <div className=" flex items-center justify-center rounded-full ring-[3px]  ring-[#ffffff]/30 w-[50px] h-[50px] p-0.5 ">
                      <img
                        src={userDetails?.image_url}
                        alt="Profile Image"
                        width={50}
                        className="rounded-full"
                      />
                    </div>
                  ) : (
                    <div className="flex font-medium font-poppins text-[20px] items-center  justify-center rounded-full border-[2px] border-[#ffffff]/30 w-[45px] h-[45px] p-1">
                      {initials}
                    </div>
                  )}
                  <div className="flex flex-col items-start">
                    <span className="text-[16px] text-[#F2F2F2] font-poppins font-medium">
                      {userDetails && userDetails.name
                        ? userDetails.name
                        : userDetails?.email.split("@")[0]}
                    </span>
                    <span className="text-[13px] text-[#D9D9D9]/60 font-poppins font-normal ">
                      {userDetails && userDetails.email}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col space-y-3  h-[100px] w-full">
                <div className="flex space-x-4 items-center w-full   mt-2">
                  <div className=" w-full bg-[#D9D9D9] h-2 rounded-[3px]">
                    <div
                      className="bg-[#16A085] h-2 rounded-[3px]"
                      style={{
                        width: `${
                          userDetails && userDetails.profile_completion
                        }%`,
                      }}
                    ></div>
                  </div>
                  <span className="text-[16px] font-medium text-[#ffffff] font-poppins">
                    {userDetails && userDetails.profile_completion}%
                  </span>
                </div>
                <button
                  onClick={openUserProfileModal}
                  className=" w-[200px] flex items-center justify-center py-2 px-4 rounded-[4px] text-[#ffffff] font-medium  text-[16px] font-poppins ring-[1px] ring-[#ffffff]/50"
                >
                  Complete Profile
                  <ChevronRightIcon className="h-5 w-5 text-white ml-1 " />
                </button>
              </div>
            </div>
          )}

          {userDetails && userDetails.profile_completion === 100 && (
            <div className="absolute bottom-1 flex  justify-between items-center  h-[70px] w-full border-t-[1px] border-[#ffffff]/20 px-6 ">
              <div className="flex  space-x-3 items-center   text-white text-center py-2   ">
                {userDetails &&
                userDetails?.image_url &&
                userDetails?.image_url !== "" &&
                userDetails?.image_url !== null ? (
                  <div className=" flex items-center justify-center rounded-full ring-[3px]  ring-[#16A085] w-[45px] h-[45px] p-1 ">
                    <img
                      src={userDetails?.image_url}
                      alt="Profile Image"
                      width={50}
                      className="rounded-full"
                    />
                  </div>
                ) : (
                  <div className="flex font-medium font-poppins text-2xl items-center  justify-center rounded-full border-[2px] border-[#16A085] w-[45px] h-[45px] p-1">
                    {initials}
                  </div>
                )}
                <div className="flex flex-col items-start">
                  <span className="text-[15px] text-[#F2F2F2] font-poppins font-medium">
                    {userDetails && userDetails.name
                      ? userDetails.name
                      : userDetails?.email.split("@")[0]}
                  </span>
                  <span className="text-[13px] text-[#D9D9D9]/60 font-poppins font-normal">
                    {userDetails && userDetails.email}
                  </span>
                </div>
              </div>
            </div>
          )}

          {showLogoutModel && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 px-4 ">
              <div className="relative">
                <div
                  className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
                  onClick={() => setShowLogoutModel(false)}
                >
                  <XIcon className="w-6 h-6 mr-2" />
                </div>
                <div className="w-full md:w-[520px] px-4 py-6 pt-10 bg-white  border-[#000000]/20 rounded-md">
                  <div className="text-[18px] font-medium font-poppins text-center ">
                    Are you sure you want to logout?
                  </div>
                  <div className="mt-0 text-[18px] font-normal font-poppins text-center ">
                    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                      <div
                        onClick={() => setShowLogoutModel(false)}
                        className="mt-4 bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer"
                      >
                        Cancel
                      </div>
                      <div
                        onClick={() => auth.signOut()}
                        className="mt-4 bg-[#ff0000] text-white text-[18px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
                      >
                        Logout
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </aside>
      </div>
    </div>
  );
};

export default MobileNavBar;
