import { XIcon } from "lucide-react";
import React, { useState } from "react";
import GmailIcon from "../../assests/gmail.svg";
import TelegramIcon from "../../assests/telegram.svg";
import WhatsappIcon from "../../assests/whatsapp.svg";
import MailIcon from "../../assests/mail.svg";
import BellIcon from "../../assests/bell_icon.svg";

const NotificationModal = ({ closeNotificationModal }) => {

  const [message, setMessage] = useState("")


  const handleSendMessage = () => {
    console.log("Message Sent Successfully !!")
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50 ">
      <div className="relative overflow-auto animate-modal">
        <div
          className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={closeNotificationModal}
        >
          <XIcon className="w-6 h-6 mr-2  " />
        </div>

        <div className="flex flex-col w-full md:w-[750px]  py-8 bg-white  border-[#000000]/20 rounded-md">
          <div className="flex  text-white space-x-4   items-center cursor-pointer px-12">
            <img src={BellIcon} alt="Bell Icon" width={25} className="" />
            <h3 className="text-[22px] text-[#000000] font-semibold font-sans ">
              Notifications
            </h3>
          </div>

          <div className="flex w-full justify-between px-4 md:px-12 mt-6">
            <div className="relative flex flex-col space-y-2 gap-x-1 items-center justify-center">
              <span className="flex items-center justify-center w-7 h-7 rounded-full border-[1px] border-[#000000]/80 text-[13px] text-[#000000]/80 font-medium font-poppins">
                1
              </span>
              <span className="text-[13px] text-[#000000] font-medium font-poppins text-center lg:text-start max-[80px] lg:max-w-auto">
                Select Client List
              </span>
              <hr className="absolute top-[12px] -right-[80px] h-[1.5px] w-[100px] bg-[#000000]/20" />
            </div>
            <div className="relative flex flex-col space-y-2 items-center justify-center">
              <span className="flex items-center justify-center w-7 h-7 rounded-full border-[1px] border-[#000000]/20 text-[13px] text-[#000000]/60 font-normal font-poppins">
                2
              </span>
              <span className="text-[13px] text-[#000000]/60 font-medium font-poppins text-center lg:text-start max-[80px] lg:max-w-auto">
                Draft a Notification
              </span>
              <hr className="absolute top-[12px] -right-[80px] h-[1.5px] w-[100px] bg-[#000000]/20" />
            </div>

            <div className="relative flex flex-col space-y-2 items-center justify-center">
              <span className="flex items-center justify-center w-7 h-7 rounded-full border-[1px] border-[#000000]/20 text-[13px] text-[#000000]/60 font-normal font-poppins">
                3
              </span>
              <span className="text-[13px] text-[#000000]/60 font-medium font-poppins text-center lg:text-start max-[80px] lg:max-w-auto">
                Select social platforms
              </span>
              <hr className="absolute top-[12px] -right-[80px] h-[1.5px] w-[100px] bg-[#000000]/20" />
            </div>

            <div className="flex flex-col space-y-2 items-center justify-center">
              <span className="flex items-center justify-center w-7 h-7 rounded-full border-[1px] border-[#000000]/20 text-[13px] text-[#000000]/60 font-normal font-poppins">
                4
              </span>
              <span className="text-[13px] text-[#000000]/60 font-medium font-poppins text-center lg:text-start max-[80px] lg:max-w-auto">
                Send Notification
              </span>
            </div>
          </div>

          <div className="flex  flex-wrap md:flex-nowrap md:space-x-10  h-auto md:h-[400px] w-full px-4 md:px-12 mt-10">
            <div className="flex flex-col space-y-1 h-full w-full md:w-[30%] ">
              <div className="flex  py-3  text-[16px] font-normal text-[#000000]/50 font-poppins border-b-[1px] border-[#000000]/10">
                <input type="checkbox" className="mr-4" />
                Client List Name
              </div>
              <div className="flex  py-3  text-[16px] font-normal text-[#000000] font-poppins border-b-[1px] border-[#000000]/10">
                <input type="checkbox" className="mr-4" />
                Jun-Mid-Term
              </div>
              <div className="flex  py-3  text-[16px] font-normal text-[#000000] font-poppins border-b-[1px] border-[#000000]120">
                <input type="checkbox" className="mr-4" />
                Jul-Mid-Term
              </div>
              <div className="flex  py-3  text-[16px] font-normal text-[#000000] font-poppins border-b-[1px] border-[#000000]/10">
                <input type="checkbox" className="mr-4" />
                Aug-Mid-Term
              </div>
              <div className="flex  py-3  text-[16px] font-normal text-[#000000] font-poppins border-b-[1px] border-[#000000]/10">
                <input type="checkbox" className="mr-4" />
                Sep-Mid-Term
              </div>
            </div>
            <div className="flex flex-col h-full w-full md:w-[70%] mt-4 md:mt-0">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="h-20 md:h-full p-4 border-[1px] border-[#000000]/20 rounded-md placeholder:text-[#000000]/60 text-[15px] text-[#000000] font-normal font-poppins"
                placeholder="Enter your Message Here"


              ></textarea>


            </div>

          </div>
          <div className="flex items-center flex-wrap gap-3 justify-between mt-6 px-4 md:px-12">
            <div className="flex items-center justify-center space-x-2">
              <img
                src={GmailIcon}
                alt="Gmail Icon"
                width={35}
                className="rounded-md"
              />

              <img
                src={TelegramIcon}
                alt="Telegram Icon"
                width={35}
                className="rounded-md"
              />

              <img
                src={WhatsappIcon}
                alt="Whatsapp Icon"
                width={35}
                className="rounded-md"
              />

              <img
                src={MailIcon}
                alt="Mail Icon"
                width={35}
                className="rounded-md"
              />
            </div>
            <button disabled={message === "" || message === null} className=" disabled:cursor-not-allowed disabled:bg-[#000000]/20 bg-black text-white text-[18px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear">
              Send Notification
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
